import React from 'react';
import './App.css';
import {RegisterPage} from "./ui/account/RegisterPage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {HomePage} from "./ui/HomePage";
import {RegisterSimplePage} from "./ui/account/RegisterSimplePage";
import {FacebookPage} from "./ui/FacebookPage";
import {store} from "./store/store";
import {Provider} from "react-redux";
import {AppointmentPage} from "./ui/AppointmentPage";
import {Error} from "./compoments/Error";
import {RemovePage} from "./ui/account/RemovePage";
import {AppState} from "./context/AppState";
import {AddressPage} from "./ui/address/AddressPage";
import {SchedulePage} from "./ui/schedule/SchedulePage";
import {FeaturedAll} from "./compoments/FeaturedAll";
import {FeaturedPage} from "./ui/FeaturedPage";
import {PromotionPage} from "./ui/promotion/PromotionPage";
import {NotificationPage} from "./ui/NotificationPage";
import {ProfilePage} from "./ui/ProfilePage";
import {ForgotPage} from "./ui/account/ForgotPage";
import {ForgotPassword} from "./ui/forgot/ForgotPassword";
import {ServicesPage} from "./ui/ServicesPage";
import {ServiceDetail} from "./ui/ServiceDetail";
function App() {
  return (
          <BrowserRouter>
                  <Provider store={store} >
                      <AppState>
                          <Routes>
                              <Route path={'/'} element={<HomePage />} />
                              <Route path={'/reset-password/:key'} element={<ForgotPage />} />
                              <Route path={'/schedule'} element={<SchedulePage />} />
                              <Route path={'/promotion'} element={<PromotionPage />} />
                              <Route path={'/notification'} element={<NotificationPage />} />
                              <Route path={'/profile'} element={<ProfilePage />} />
                              <Route path={'/delete-account'} element={<RemovePage />} />
                              <Route path={'/address'} element={<AddressPage />} />
                              <Route path={'/featured'} element={<FeaturedPage />} />
                              <Route path={'/services'} element={<ServicesPage />} />
                              <Route path={'/serviceDetail/:id'} element={<ServiceDetail />} />
                              <Route path={'/face'} element={<FacebookPage />} />
                              <Route path={'/appointment/:id/:independent/:promoId'} element={<AppointmentPage />} />
                              <Route path={'/simple'} element={<RegisterSimplePage />} />
                              <Route path={'/multi'} element={<RegisterPage />} />
                              <Route path={'/forgot/:code'} element={<ForgotPassword />} />
                              <Route path={'*'} element={<Error />} />
                          </Routes>
                      </AppState>
                  </Provider>
          </BrowserRouter>
  );
}
export default App;
