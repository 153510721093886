import axios from "axios";
import {API_URL, BASE_URL} from "../utils/url";
import {getCurrentUser} from "../services/LocalService";

export const apiClient = axios.create({
  baseURL: BASE_URL + API_URL,
  headers: {
    'Cache-Control': 'no-cache',
    Accept: 'application/json; version=0',
    'Content-Type': 'application/json; charset=utf-8',
  },
  timeout: 50000,
});
apiClient.interceptors.request.use(
    async config => {
      const  current = await getCurrentUser();
      let token = '';
      if (current) {
        token = current.token;
      }
      return {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
        },
      };
    },
    error => Promise.reject(error),
);
// apiClient.interceptors.request.use(function ( config) {
//   // Do something before request is sent
// const  current = getCurrentUser();
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });
