import {getHttp, postHttp} from "../../api/HttpClient";
import {buildUrlClient} from "../../utils/BuildUrlClient";
import {
    setCheckToken,
    setLogin,
    setLoginError,
    setLogout,
    setRegister,
    setRegisterError,
    setRemoveAccount
} from "../slices/authSlice";
import {clearCurrentUser, getCurrentUser} from "../../services/LocalService";
import {buildUrlBase} from "../../utils/BuildUrlBase";
// import {signOut,} from "firebase/auth";
// import {auth} from "../../firebase/firebaseConfig";

export const onCheckToken = () => {
    return async (dispatch: any, getState: any) => {
        const checkSession =  await getCurrentUser()
        if ( checkSession !== '') {
            dispatch(setCheckToken({ status: 'authenticated',  message: '', session: checkSession}))
        } else {
            dispatch(setCheckToken({ status: 'notAuthenticated',  message: '', session: null}))
        }
    }
}


export const onLogin = (body: any) => {
    return async (dispatch: any, getState: any) => {
        const  {response, success, message} =  await postHttp(buildUrlBase('login'), body)
        if (success) {
            const {id,name,auth_token, last_name, is_support} = response;
            dispatch(setLogin({status: 'authenticated', message: message, session: {id, name, last_name, token: auth_token, is_support} }))
        } else {
            dispatch(setLoginError({status: 'notAuthenticated', message: message, session: null }))
        }
    }
}
export const onRegister = (body: any) => {
    return async (dispatch: any, getState: any) => {
        const  {response, success, message, errors} =  await postHttp(buildUrlBase('registerWeb'), body)
        if (success) {
            dispatch(setRegister({status: 'authenticated', message: '', session: response, success: true }))
        } else {
            const {code, error} = errors;
            let messages = [];
            if (code == 422) {
                for (let key in error) {
                    messages.push({field: key, value: error[key][0] })
                }
            }
            dispatch(setRegisterError({status: 'notAuthenticated', messages: messages, session: response, success: false }))
        }
    }
}
export const onLogout = () => {
    return async (dispatch: any, getState: any) => {
        const currentUser = await getCurrentUser();
        if (currentUser){
            const {token} =currentUser;
            const body = {token}
            const {response,success, errors, message} =  await postHttp(buildUrlBase('logout'), body);
            if (success) {
                await clearCurrentUser();
                logOutSocial();
                dispatch(setLogout({status: 'notAuthenticated', message: '', session: null}))
            } else {
                const {code} = errors;
                if (code == 401) {
                    await clearCurrentUser();
                    logOutSocial();
                    dispatch(setLogout({status: 'notAuthenticated', message: '', session: null}))
                }

            }
            if (message == 'Token is Invalid') {
                await clearCurrentUser();
                logOutSocial();
                dispatch(setLogout({status: 'notAuthenticated', message: '', session: null}))
            }
        } else {
            dispatch(setLogout({status: 'notAuthenticated', message: '', session: null}))
        }

    }
}

export const onRemoveAccount = (body: any) => {
    return async (dispatch: any, getState: any) => {
        const  {response, success, message} =  await postHttp(buildUrlClient('deleteAccountCli'), body)
        if (success) {
            logOutSocial();
            dispatch(setRemoveAccount({status: 'notAuthenticated', message: message, session: null, success: success}))
        } else {
            dispatch(setRemoveAccount({status: 'notAuthenticated', message: message, session: "", success: success}))
        }
    }
}
const logOutSocial = () => {
    // signOut(auth).then(() => {
    // }).catch((error) => {
    // });
}

export const onLoginFace = (body: any) => {
    return async (dispatch: any, getState: any) => {
        const {response, success,errors, message} = await postHttp(buildUrlClient('loginFacebook'), body);
        if (success) {
            const {id,name,auth_token, last_name} = response;
            dispatch(setLogin({status: 'authenticated', message: message, session: {id, name, last_name, token: auth_token} }))
        } else {
            dispatch(setLoginError({status: 'notAuthenticated', message: message, session: null }))
        }
    }
}
