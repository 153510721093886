import {Header} from "../../layout/header";
import {Footer} from "../../layout/footer";
import {ScheduleActive} from "./ScheduleActive";
import {ScheduleInactive} from "./ScheduleInactive";

export const SchedulePage = () => {

  return <>
      <Header />
      <div className={'container'}>
        <div className={'row'}>
          <div className="d-sm-flex justify-content-sm-between  mt-2 mt-sm-3">
            <h2 className="mb-0">Mis citas</h2>
          </div>
        </div>
        <ul className="nav nav-tabs nav-tabs-line mb-3">
          <li className="nav-item"><a className="nav-link active" data-bs-toggle="tab" href="#tab-3-1"> Activos </a>
          </li>
          <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab-3-2"> Inactivos </a></li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane show active" id="tab-3-1">
            <ScheduleActive />
          </div>
          <div className="tab-pane" id="tab-3-2">
           <ScheduleInactive />
          </div>
        </div>
      </div>
      <Footer />
  </>
}
