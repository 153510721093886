import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface FilterState {
    departmentId: number,
    typeId: number,
    searchText: string,
}

const initialState: FilterState = {
    departmentId: 0,
    typeId: 0,
    searchText: '',
}

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setType: (state, action: PayloadAction<any>) => {
            state.typeId = action.payload.typeId
        },
        setDepartmentId: (state, action: PayloadAction<any>) => {
            state.departmentId = action.payload.departmentId
        },
        setSearchText: (state, action: PayloadAction<any>) => {
            state.searchText = action.payload.searchText
        },
    },
})

// Action creators are generated for each case reducer function
export const { setType, setDepartmentId, setSearchText } = filterSlice.actions
