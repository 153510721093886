import {Header} from "../layout/header";
import {Footer} from "../layout/footer";

export const FacebookPage = () => {
  return <div>
          <Header />
          <div className={'container'}>
              <h1>Facebook </h1>
          </div>
          <Footer />
  </div>
}
