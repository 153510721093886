/**
 * geneta lista de los servicios
 * @param items
 */
import {Counter} from "../../models/Counter";
import {ServiceModel} from "../../models/ServiceModel";

export const getListService = async (items: any, countServices: Counter[]) => {
  let counterSelected = 0;
  for (let i = 0; i < items.length; i++) {
      let item = items[i];
      let newListServices = [];
      let {service} = item;
    for (let k = 0; k < service.length; k++) {
      const serviceProvider = countServices.find((ele: Counter) => ele.serviceId == service[k].id);
          if (serviceProvider) {
            for (let h = 0; h < serviceProvider.count; h++) {
              let serviceModel: ServiceModel = {
                itemId: item?.id + '' + service[k]?.id + '' +k + ''+ h,
                professionalId: 0,
                serviceId: service[k]?.id,
                color: service[k]?.color,
                appointmentServiceId: null,
                date: '',
                salonId: null,
                hours: [],
                selected: counterSelected === 0 ? true: false,
                state: 0,
                name: service[k].name,
                cost: service[k].cost,
                type: service[k]?.type_id,
                typeId: service[k]?.type_id,
                typeName: service[k]?.type_name,
                minTime: service[k]?.min_time,
                maxTime: service[k]?.max_time,
                description: service[k]?.description,
                message: '',
              };
              newListServices.push(serviceModel);
              counterSelected++;
            }
          }
      }
    items[i].service = newListServices;
  }
  return items;
}
