import {getHttp} from "../../api/HttpClient";
import {buildUrlClient} from "../../utils/BuildUrlClient";
import {setCounterAddrress} from "../slices/appointmentSlice";

export const checkAddress = () => {
    return async (dispatch: any, getState: any) => {
        const {response, success} = await getHttp(buildUrlClient('checkAddress'));
        if (success) {
            dispatch(setCounterAddrress({counterAddrress: response?.count}));
        }
    }
}
