import {useEffect, useState} from "react";
import logo from "./../assets/images/logo.png"
import { useAppSelector, useAppDispatch } from '../hooks/hook'
import {getDepartments} from "../store/thunks/departmentThunk";
import Select from "react-dropdown-select";
import {RootState} from "../store/store";
import {ModalAccount} from "../compoments/ModalAccount";
import {onLogout} from "../store/thunks/authTrunk";
import {setShowModal, setShowRegister} from "../store/slices/authSlice";
import {setDepartmentId, setSearchText} from "../store/slices/filterSlice";
import {getFeatured} from "../store/thunks/featuredThunk";
import {getProviders} from "../store/thunks/providerThunk";
import {useNavigate} from "react-router-dom";
import {ShowNotification} from "../compoments/ShowNotification";
import {ModalRegister} from "../compoments/ModalRegister";
export const Header = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const {departments} = useAppSelector((state: RootState) => state.departments);
    const {searchText, typeId, departmentId} = useAppSelector((state: RootState) => state.filter);
    const {status, showModal, session, showRegister} = useAppSelector((state: RootState) => state.auth);
    const [showProfile, setShowProfile] = useState(false);
    const callback = ($event: any) => {
      if (showProfile) {
          setShowProfile(false);
          $event.stopPropagation();

      }
    }
    useEffect(() => {
        const handleClick = ($event: any) => {
            callback($event);
        };
        document.addEventListener('click', handleClick);
        return () => {
        document.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
        (async () => await loadData())();
    }, []);
    const loadData = () => {
        dispatch(getDepartments())
    }
    const onSelected = (element: any) => {
        dispatch(setDepartmentId({departmentId: element.id}))
    }
    const onProfile = ($event: any) => {
        $event.stopPropagation();
      setShowProfile(true)
    }
    const logOut = () => {
      dispatch(onLogout())
     return navigate(`/`);
    }

    const onAccount = () => {
        dispatch(setShowModal({showModal: true}))
    }
    const getFullName = () => {
        let fullName = 'A';
      if (session) {
          fullName = session?.name?.substring(0,1) + session?.last_name?.substring(0,1);
      }
      return fullName?.toUpperCase();
    }

    const onCloseModal = () => {
      dispatch(setShowModal({showModal: false}))
    }
    const onSubmit = () => {

    }
    const onSearch = () => {
        dispatch(getFeatured(1, searchText, typeId, departmentId));
        dispatch(getProviders(1, searchText, typeId, departmentId));
    }
    const onChangeText = (text: any) => {
      dispatch(setSearchText({searchText: text.target.value}))
    }
    const onClient = () => {
      dispatch(setShowRegister({showRegister: true}))
    }
    const onCloseRegister = () => {
        dispatch(setShowRegister({showRegister: false}))
    }
    const onAbort = () => {
    }
    return <>
        <header className="navbar-light navbar-sticky">
               <nav className="navbar navbar-expand-xl z-index-9">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img className="light-mode-item navbar-brand-item" src={logo} alt="logo" />
                    </a>
                    {/*Select departments*/}
                    <div className="navbar-collapse collapse">
                        <div className={'w-100'}>
                            <Select options={departments}
                                    placeholder={' Seleecion el departamento'}
                                    labelField="name"
                                    valueField="id"
                                    onChange={(values) => onSelected(values[0])} values={[]} />
                        </div>

                    </div>

                    <div className="navbar-collapse collapse" >
                        <div className="col-12">
                            <div className="nav my-3 my-xl-0 px-4 flex-nowrap align-items-center">
                                <div className="nav-item w-100">
                                        <div className="input-group">
                                            <input className="form-control border-secondary"
                                                   type="search"
                                                   onChange={(value: any) => onChangeText(value)}
                                                   placeholder="Buscar..." aria-label="Search" value={searchText}/>
                                                <button className="btn btn-success m-0" type="button" onClick={() => onSearch()}>Buscar</button>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul className="nav flex-row align-items-end">
                        {/*icons calendar*/}
                        {status == 'authenticated' && <li className="nav-item ms-0 ms-sm-2 ">
                            <a className="btn btn-light btn-round mb-0" href="/schedule">
                                <i className="bi bi-calendar fa-fw"></i>
                            </a>
                        </li>
                        }
                        {status == 'authenticated' &&
                            <li className="nav-item ms-0 ms-sm-2 ">
                                <a className="btn btn-light btn-round mb-0" href="/notification">
                                    <i className="bi bi-bell fa-fw"></i>
                                </a>
                            </li>
                        //     <li className="nav-item ms-2 ms-sm-3 dropdown">
                        //     <a className="btn btn-light btn-round mb-0" href="#" role="button" data-bs-toggle="dropdown"
                        //                                      aria-expanded="false" data-bs-auto-close="outside">
                        //         <i className="bi bi-bell fa-fw"></i>
                        //     </a>
                        //     <span className="notif-badge animation-blink"></span>
                        //     <div
                        //         className="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md p-0 shadow-lg border-0">
                        //         <div className="card bg-transparent">
                        //
                        //             <ShowNotification />
                        //             <div className="card-footer bg-transparent border-0 py-3 text-center position-relative">
                        //                 <a href="/notification" className="stretched-link">ver</a>
                        //             </div>
                        //         </div>
                        //     </div>
                        // </li>
                        }

                        {/*profile icon*/}
                        {status == 'authenticated' ?
                            <li className="nav-item ms-3 dropdown">
                                <a className="avatar avatar-sm p-0 " onClick={($event) => onProfile($event)} href="#" id="profileDropdown" role="button"
                                   data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown"
                                   aria-expanded="true">
                                    {/*<img className="avatar-img rounded-circle" src={user} alt="avatar" />*/}
                                    <div
                                        className="avatar-img rounded-circle border border-3 border-info bg-dark">
                                                        <span
                                                            className="text-white position-absolute top-50 start-50 translate-middle fw-bold">{getFullName()}</span>
                                    </div>
                                </a>
                                <ul className={`dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3 ${showProfile ? "show" : ""}`}
                                    aria-labelledby="profileDropdown">
                                    <li className="px-3 mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar me-2">
                                                <div className="avatar align-middle">
                                                    <div
                                                        className="avatar-img rounded-circle border border-3 border-info bg-dark">
                                                        <span
                                                            className="text-white position-absolute top-50 start-50 translate-middle fw-bold">{getFullName()}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <a className="h6" href="#">{session?.name}</a>
                                                <p className="small m-0">{session?.last_name}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li><a className="dropdown-item" href="/profile"><i className="bi bi-person fa-fw me-2">

                                    </i>Mi Perfil</a></li>
                                    <li><a className="dropdown-item" href="/schedule">
                                        <i className="bi bi-calendar fa-fw me-2"></i> Mis citas </a></li>

                                    {session?.is_support == 1 && <li>
                                        <button className="dropdown-item" onClick={() => onClient()}>
                                        <i className="bi bi-calendar fa-fw me-2"></i> Registrar cliente</button>
                                        </li>}

                                    <li><a className="dropdown-item" href="/address">
                                        <i className="bi bi-calendar fa-fw me-2"></i> Mis direcciones</a></li>
                                    {/*<li><a className="dropdown-item" href="#">*/}
                                    {/*    <i className="bi bi-info-circle fa-fw me-2"></i>Ayuda</a>*/}
                                    {/*</li>*/}

                                    <li><a className="dropdown-item" href="/notification">
                                        <i className="bi bi-bell fa-fw me-2"></i>Notificaciones</a>
                                    </li>
                                    <li>
                                        <button className="dropdown-item bg-danger-soft-hover" onClick={() => logOut()}>
                                        <i className="bi bi-power fa-fw me-2"></i>Cerrar Sesion</button>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                </ul>
                            </li>
                            :
                            <div className="navbar-nav  d-lg-inline-block">
                                <button className="btn btn-success-soft mb-0" onClick={() => onAccount()}><i className="fas fa-sign-in-alt me-2">

                                </i>Iniciar Sesión</button>
                            </div>
                        }

                    </ul>
                </div>
            </nav>
                <nav className={'navbar d-xl-none d-sm-block '}>
                    <div className={'col-12 p-1'}>
                        <Select options={departments}
                                labelField="name"
                                valueField="id"
                                onChange={(values) => onSelected(values)} values={[]} />
                    </div>
                    <div className={'col-12 p-1'}>
                        <form className="input-group">
                            <input className="form-control border-secondary" type="search"
                                   placeholder="Buscar..." aria-label="Search" />
                            <button className="btn btn-success m-0" type="submit">Buscar</button>
                        </form>
                    </div>
                </nav>
        </header>
        <ModalAccount
            show={showModal}
            onHide={() => onCloseModal()}
        />
        <ModalRegister
            show={showRegister}
            onHide={() => onCloseRegister()}
        />
    </>
}
