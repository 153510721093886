import { createSlice } from '@reduxjs/toolkit'

export interface ProviderState {
    page: number,
    providers: any[],
    next_page: null,
    isLoading: boolean
}

const initialState: ProviderState = {
    page: 1,
    providers: [],
    next_page: null,
    isLoading: false,
}

export const providerSlice = createSlice({
    name: 'provider',
    initialState,
    reducers: {
        isLoading: (state, action) => {
            state.isLoading = action.payload.loading;
        },
        setProviders: (state,action) => {
            state.isLoading = false;
            state.providers =  action.payload.page == 1? action.payload.providers : [...state.providers, ...action.payload.providers];
            state.page =  action.payload.page;
            state.next_page = action.payload.next_page;
        },
    },
})

// Action creators are generated for each case reducer function
export const { isLoading, setProviders } = providerSlice.actions
