import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PaymentModel} from "../../models/PaymentModel";
import {PaymentMethods} from "../../utils/Constants";
import {HourDayModel} from "../../models/HourDayModel";
import {PromotionModel} from "../../models/PromotionModel";
import {ServiceProviderModel} from "../../models/ServiceProviderModel";

export interface AppointmentState {
    stepper: string;
    provider: any;
    services: any[];
    payment: PaymentModel,
    hours: HourDayModel[];
    isLoading: boolean,
    promotion: PromotionModel;
    discount_type: string;
    discount_rate: number | undefined | null;
    showModalAddrress: boolean;
    counterAddrress: number;
    reloadAddress: string;
    serviceProvider?: ServiceProviderModel;
}

const initialState: AppointmentState = {
    stepper:'calendar',
    provider: [],
    services: [],
    payment: {},
    hours: [],
    isLoading: false,
    promotion: {},
    discount_type: '',
    discount_rate: null,
    showModalAddrress: false,
    counterAddrress: 0,
    reloadAddress: '',
    serviceProvider: {},
}

export const appointmentSlice = createSlice({
    name: 'appointment',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload.loading;
        },

        initPayment: (state, action) => {
            state.payment = {
                id:  action.payload.id,
                paymentId: 0,
                appointments: [],
                counters: [],
                independent: action.payload.independent,
                services: [],
                paymentMethod: PaymentMethods.QR,
                appointmentList: [],
            }
        },
        setPromotion: (state,action) => {
            state.promotion = action.payload.promotion;
        },
        setPayment: (state,action) => {
            state.isLoading = false;
            state.payment = action.payload.payment;
        },
        setStepper: (state,action) => {
            state.stepper = action.payload.stepper;
        },

        setProvider: (state,action) => {
            state.provider = action.payload.provider;
        },

        setServices: (state,action) => {
            state.services = action.payload.services;
        },
        setCounters: (state,action) => {
            state.payment.counters = action.payload.counters;
        },

        setServicesCalendar: (state,action) => {
            state.payment.services = action.payload.services;
        },
        setHoursByDay: (state,action) => {
            state.hours = action.payload.hours;
        },

        setAssignedSchedule: (state,action) => {
            state.hours = action.payload.hours;
            state.payment.services = action.payload.services;
        },
        setServicesSchedule: (state,action) => {
            state.payment.services = action.payload.services;
        },
        setAppointments: (state,action) => {
            state.payment.appointments = action.payload.appointments;
            state.stepper = action.payload.stepper;
        },
        setAppointmentList: (state,action) => {
            state.payment.appointmentList = action.payload.appointmentList;
        },
        setApp: (state,action) => {
            state.payment.appointmentList = action.payload.appointmentList;
        },
        setPaymentMethod: (state,action) => {
            state.payment.paymentMethod = action.payload.paymentMethod;
        },
        setShowModalAddrress: (state,action) => {
            state.showModalAddrress = action.payload.showModalAddrress;
        },
        setCounterAddrress: (state,action) => {
            state.counterAddrress = action.payload.counterAddrress;
        },
        setReloadAddress: (state,action) => {
            state.reloadAddress = action.payload.reloadAddress;
        },
        setServiceProvider: (state, action: PayloadAction<any>) => {
            state.serviceProvider = action.payload.serviceProvider;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setLoading, setPayment, setStepper,
    setProvider, setServices, initPayment,
    setCounters, setServicesCalendar, setHoursByDay,
    setAssignedSchedule, setAppointments, setAppointmentList,
    setPaymentMethod, setServicesSchedule,
    setPromotion, setShowModalAddrress,
    setServiceProvider,
    setCounterAddrress, setReloadAddress} = appointmentSlice.actions
