import {ServiceModel} from "../../models/ServiceModel";
import {HourModel} from "../../models/HourModel";

export const addSchedule = async (selectedDate: string, serviceSelected: ServiceModel, userId: number, servicesParam: any, hoursTem: HourModel[], professionalId: number) => {
    let typeNew = [];
    if (serviceSelected) {
        let serviceSelectedNew = {
            ...serviceSelected,
            hours: hoursTem,
            date: selectedDate,
            userId: userId,
            serviceId: serviceSelected.serviceId,
            professionalId: professionalId,
            salonId: userId
        }

    for (const key in servicesParam) {
        let type = servicesParam[key];
        let {service} = servicesParam[key];
        let servicesNew = [];
        for (const up in service) {
            const item = service[up];
            if (item.selected && serviceSelected.itemId == item.itemId) {
              servicesNew.push(serviceSelectedNew)
            } else {
              servicesNew.push(item);
            }
        }
        type = {...type, service: servicesNew}
        typeNew.push(type);
    }
}
  return typeNew;
}
