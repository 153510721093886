export const getServiceSelected = (services: any) => {
  for (const key in services) {
    const {service} = services[key];
    for (const up in service) {
      const item = service[up];
      if (item.selected) {
        return item;
      }
    }
  }
  return null
}
