export const hourCheckedByDay = async (hours: any, services: any) => {
    let newHours = [];
    for (const k in hours) {
        const hour =  hours[k];
        let assigned = await checkAssigned(hour, services);
        newHours.push({...hour, assigned: assigned});
    }
    return newHours;
}
const checkAssigned = async (hourParam: any, servicesParam: any) => {
    for (const kt in servicesParam) {
        const servicesTemp = servicesParam[kt].service;
        for (const ks in servicesTemp) {
            const serviceTemp = servicesTemp[ks];
            if(serviceTemp.hours.some((hh: any) => hh.hourId === hourParam.hour_id)) {
                return true
            }
        }
    }
    return false
}
