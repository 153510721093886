import {Spinner} from "react-bootstrap";
interface Props {
    loading: boolean | undefined;
}
export const SpinnerApp = ({loading}: Props) => {
    return (
        <>
        { loading && <Spinner animation="border" role="status">
            <span className="visually-hidden">Laoding...</span>
        </Spinner>
        }
        </>
    );
}
