export const getServicesBody = async (services: any[]) => {
  const serviceList = services.map(item => item.service);
  let newList = [];
  for (let key in services) {
    const {service} = services[key];
    for (let ky in service) {
      const servi = service[ky]
      newList.push({
        itemId: servi.itemId,
        appointmentServiceId: servi.appointmentServiceId,
        serviceId: servi.serviceId,
        date: servi.date,
        profesionalId: servi.professionalId,
        salonId: servi.salonId,
        note: servi.note ? servi.note: '',
        type: servi.type,
        hours: servi.hours
      });
    }
  }
  return newList;
}
