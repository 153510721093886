import {getHttp} from "../../api/HttpClient";
import {setDepartments} from "../slices/departmentsSlice";
import {buildUrlWeb} from "../../utils/BuildUrlWeb";
export const getDepartments = () => {
    return async (dispatch: any, getState: any) => {
        const  {response, success} =  await getHttp(buildUrlWeb('departments'))
        if (success) {
            const list = [{id: 0, name: '-- Seleccione un Departamento --'}];
            response.map((item: any) => {
                list.push({id: item.id, name: item.name});
            })

            dispatch(setDepartments({departments: list}))
        }
    }
}
