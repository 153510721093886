import { useAppSelector, useAppDispatch } from '../hooks/hook'
import {useEffect} from "react";
import {getTypes} from "../store/thunks/typeThunk";
import {RootState} from "../store/store";
import {setType} from "../store/slices/filterSlice";
import {getFeatured} from "../store/thunks/featuredThunk";
import {getProviders} from "../store/thunks/providerThunk";
export const Types = () => {
  const {types} = useAppSelector((state: RootState) => state.types);
  const {typeId, searchText, departmentId} = useAppSelector((state: RootState) => state.filter);
  const dispatch = useAppDispatch()
  useEffect(() => {
    (async () => await loadData())();
  }, []);

  const loadData = () => {
    dispatch(getTypes())
  }
  const onSelected = (item: any) => {
    if (typeId == item.id) {
      dispatch(setType({typeId: 0}))
      dispatch(getFeatured(1, searchText, 0, departmentId));
      dispatch(getProviders(1, searchText, 0, departmentId));
    } else {
      dispatch(setType({typeId: item.id}))
      dispatch(getFeatured(1, searchText, item.id, departmentId));
      dispatch(getProviders(1, searchText, item.id, departmentId));
    }
  }

  return <section>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="d-sm-flex justify-content-sm-between  mt-2 mt-sm-3">
            <h2 className="mb-0">Elija una categoría</h2>
          </div>
          <div className="tiny-slider arrow-round arrow-creative arrow-blur arrow-hover" >
            <div className="tns-outer" >
              <div  className="tns-ovh">
                  <div className="tiny-slider-inner  tns-slider tns-carousel tns-horizontal types-center" >
                    {types?.map((type, index) => (
                        <div key={index} className="text-center tns-item tns-slide-cloned" onClick={() => onSelected(type)}>
                          <div className="avatar avatar-xxl mb-2">
                            <img className="avatar-img rounded-circle type-image-circle" src={type.medium} />
                          </div>
                          {typeId == type.id ?<div>
                            <a href="#" className="badge bg-info bg-opacity-10 text-info me-2"><i
                                className="fas fa-circle small fw-bold"></i> {type.name} </a>
                          </div> :<p className="mb-0 small">{type.name}</p> }

                        </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}
