import { createSlice } from '@reduxjs/toolkit'
export interface ConfirmState {
    confirmSchedule?: boolean;
    qr?: any;
    appointmentIds?: string;
    paymentId?: string;
    appointments?: any
}
const initialState: ConfirmState = {
    confirmSchedule: false,
    qr: null,
    appointmentIds: '',
    paymentId: '',
    appointments: [],

}
export const confirmSlice = createSlice({
    name: 'confirm',
    initialState,
    reducers: {
        setConfirm: (state, action) => {
            state.confirmSchedule = action.payload.confirmSchedule;
            state.qr = action.payload.qr;
            state.appointmentIds = action.payload.appointmentIds;
            state.paymentId = action.payload.paymentId;
        },
        setResultConfirm: (state, action) => {
            state.appointments = action.payload.appointments;
        },
    },
})
export const { setConfirm, setResultConfirm } = confirmSlice.actions
