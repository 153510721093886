import React, {useEffect, useState} from "react";
import {Badge, Button, ListGroup, Modal} from "react-bootstrap";
import {useGeolocated} from "react-geolocated";
import { GoogleMap, LoadScript, MarkerF, useJsApiLoader} from "@react-google-maps/api";
import {GOOGLE_POSITION, Key} from "../utils/Constants";
import Autocomplete from "react-google-autocomplete";

interface Props {
    visible: boolean,
    onClose: Function,
    onSelect: Function,
    list: any
}
export const ProfessionalModal = ({visible, onClose, onSelect, list}: Props) => {
    return <Modal show={visible} onHide={() =>onClose()}  size="lg">
        <Modal.Header closeButton>
            <Modal.Title> Seleccione un profesional</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className={'row p-3'}>
                <ListGroup as="ol">
                    {list.map((item: any, index: number) => (
                        <ListGroup.Item key={index} onClick={() => onSelect(item)}
                            as="li"
                            className="d-flex justify-content-between align-items-start">
                            <div className="avatar avatar-lg flex-shrink-0">
                                <img className="avatar-img rounded-circle " src={item.perfilLink}
                                     alt="avatar" />
                            </div>
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{item.name}</div>
                                {item.last_name}
                            </div>
                            <div className="icon-md-app bg-orange bg-opacity-15 text-orange rounded-circle align-content-end"
                                 onClick={() => onSelect(item) }>
                                <i className="fas fa-arrow-right" />
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => onClose()}>
                Cerrar
            </Button>
        </Modal.Footer>
    </Modal>
};
