import logo from "./../assets/images/logo.png"
import googlePlay from "./../assets/images/google-play.svg";
import appStore from "./../assets/images/app-store.svg";

import whats from "./../assets/images/whats.png"
export const Footer = () => {
  return <div className="container-fluid">
    <footer className="pt-5 bg-light">
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-3">
            <a className="me-0" href="index.html">
              <img className="light-mode-item h-40px" src={logo} alt="logo" />
            </a>
            <p className="my-3">Bienvenido a servicios de bienestar a domicilio! </p>
            <ul className="list-inline mb-0 mt-3">
              <li className="list-inline-item"><a className="btn btn-white btn-sm shadow px-2 text-facebook" href="#"><i
                  className="fab fa-fw fa-facebook-f"></i></a></li>
              <li className="list-inline-item"><a className="btn btn-white btn-sm shadow px-2 text-instagram"
                                                  href="#"><i className="fab fa-fw fa-instagram"></i></a></li>
              <li className="list-inline-item"><a className="btn btn-white btn-sm shadow px-2 text-twitter" href="#"><i
                  className="fab fa-fw fa-twitter"></i></a></li>
              <li className="list-inline-item"><a className="btn btn-white btn-sm shadow px-2 text-linkedin" href="#"><i
                  className="fab fa-fw fa-linkedin-in"></i></a></li>
            </ul>
          </div>
          <div className="col-lg-6">
            <div className="row g-4">
              <div className="col-6 col-md-4">
                <h5 className="mb-2 mb-md-4">Compañía</h5>
                <ul className="nav flex-column">
                  {/*<li className="nav-item"><a className="nav-link" href="#">Sobre nosotros</a></li>*/}
                  {/*<li className="nav-item"><a className="nav-link" href="#">Contáctenos</a></li>*/}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <h5 className="mb-2 mb-md-4">Contact</h5>
            <p className="mb-2">

              Contactenos : <a className="avatar avatar-sm p-0" target='_blank' href="https://wa.me/59177011641">
              <img className="avatar-img rounded-circle" src={whats} alt="avatar" />
            </a>
            </p>
            <p className="mb-0">Email:<span className="h6 fw-light ms-2">bela.to.go@gmail.com</span></p>
            <div className="row g-2 mt-2">
              <div className="col-6 col-sm-4 col-md-3 col-lg-6">
                <a target={'_blank'} href="https://play.google.com/store/apps/details?id=com.b2g.client&pcampaignid=web_share"> <img src={googlePlay} alt="" /> </a>
              </div>
              <div className="col-6 col-sm-4 col-md-3 col-lg-6">
                <a target={'_blank'} href="https://apps.apple.com/bo/app/bela2go/id1527684447"> <img src={appStore} alt="app-store" /> </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-4 mb-0" />
          <div className="py-3">
            <div className="container px-0">
              <div className="d-lg-flex justify-content-between align-items-center py-3 text-center text-md-left">
                <div className="text-body text-primary-hover"> Copyrights ©2024 Bela2go V. 13
                </div>
                <div className="justify-content-center mt-3 mt-lg-0">
                  <ul className="nav list-inline justify-content-center mb-0">
                    <li className="list-inline-item"><a className="nav-link"  target={'_blank'} href="https://www.bela2go.com.bo/privacidad">Condiciones de uso</a></li>
                    <li className="list-inline-item"><a className="nav-link pe-0" target={'_blank'}  href="https://www.bela2go.com.bo/privacidad">Política de privacidad</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </div>
    </footer>
  </div>
}
