import {Modal} from "react-bootstrap";
import {useState} from "react";
import {Login} from "./Login";
import {Register} from "./Register";

export const ModalAccount = (props: any) => {
    const [activeTab, setActiveTab] = useState(1);
    const onTab = (tab: number) => {
        setActiveTab(tab);
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <ul className="nav nav-tabs mb-3">
                    <li className="nav-item">
                        <a className={`nav-link ${activeTab == 1 ? " active " : ""}  `}  data-bs-toggle="tab" href="#tab-1" onClick={() => onTab(1)}> Inicio de Sesion </a></li>
                    <li className="nav-item">
                        <a className={`nav-link ${activeTab == 2 ? " active " : ""}  `} data-bs-toggle="tab" href="#tab-1-2" onClick={() => onTab(2)}> Registrate </a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className={`tab-pane  ${activeTab == 1 ? " show active " : ""} `} id="tab-1">
                      <Login />
                    </div>
                    <div className={`tab-pane ${activeTab == 2 ? " show active " : ""} `} id="tab-2">
                      <Register />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
