import { createSlice } from '@reduxjs/toolkit'
import {clearCurrentUser, saveCurrentUser, saveParentSession} from "../../services/LocalService";
export interface AuthState {
    status: 'checking' | 'authenticated' | 'not-authenticated';
    token: string | null;
    message: string;
    session: any | null;
    showModal: boolean;
    showRegister: boolean;
    messages: any;
    success: boolean;
    parentId: string;
}

const initialState: AuthState = {
    status: 'checking',
    token: null,
    message: '',
    session: null,
    showModal: false,
    showRegister: false,
    messages: null,
    success: false,
    parentId: '',
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCheckToken: (state, action) => {
            state.status = action.payload.status;
            state.message = action.payload.message;
            state.session = action.payload.session;
            state.parentId = action.payload.parentId
        },
        setLogin: (state, action) => {
            state.status = action.payload.status;
            state.message = action.payload.message;
            state.session = action.payload.session;
            state.showModal = false;
            saveCurrentUser(action.payload.session).then()
        },
        setLoginError: (state, action) => {
            state.status = action.payload.status;
            state.message = action.payload.message;
            state.session = action.payload.session;
            saveCurrentUser(action.payload.session).then()
        },
        setRegister: (state, action) => {
            if (action.payload.success) {
                state.status = action.payload.status;
                state.message = action.payload.messages;
                state.session = action.payload.session;
                state.showModal = false;
                saveCurrentUser(action.payload.session).then()
            } else {
                state.messages = action.payload.messages;
            }
        },
        setRegisterError: (state, action) => {
            if (action.payload.success) {
                state.status = action.payload.status;
                state.message = action.payload.messages;
                state.session = action.payload.session;
                saveCurrentUser(action.payload.session).then()
            } else {
                state.messages = action.payload.messages;
            }
        },

        setLogout:(state, action) => {
            state.status = action.payload.status;
            state.message = action.payload.message;
            state.session = action.payload.session;
            clearCurrentUser().then()
        },

        setRemoveAccount:(state, action) => {
            if (action.payload.success) {
                state.status = action.payload.status;
                state.message = action.payload.message;
                state.session = action.payload.session;
                clearCurrentUser().then()
            } else {
                state.message = action.payload.message;
            }
        },
        setShowModal:(state, action) => {
            state.showModal = action.payload.showModal;
        },
        setParentLogin: (state, action) => {
            state.parentId = action.payload.parentId
            saveParentSession(action.payload.parentId).then()
        },
        setShowRegister: (state, action) => {
            state.showRegister = action.payload.showRegister
        },
    },
})

// Action creators are generated for each case reducer function
export const { setCheckToken, setLogin, setLoginError, setRegister,
    setLogout, setRemoveAccount, setShowModal,
    setRegisterError, setParentLogin,
    setShowRegister} = authSlice.actions
