import {deleteHttp, getHttp, postHttp} from "../../api/HttpClient";
import {buildUrlClient} from "../../utils/BuildUrlClient";
import {
    setAppointmentList,
    setAppointments,
    setHoursByDay, setLoading,
    setServicesCalendar
} from "../slices/appointmentSlice";
import {getListService} from "../../core/calendar/listService";
import {Counter} from "../../models/Counter";
import {setStateHours} from "../../core/calendar/setStateHours";
import {getServicesBody} from "../../core/calendar/getServicesBody";
import {getCurrentUser} from "../../services/LocalService";
import {setConfirm} from "../slices/confirmSlice";
import {hourCheckedByDay} from "../../core/calendar/hourCheckedByDay";
import {getServiceSelected} from "../../core/calendar/getServiceActive";
import {PromotionModel} from "../../models/PromotionModel";
export const getServicesCalendar = (params: any, countServices: Counter[] = [], provider: any, selectedDate: string ) => {
    return async (dispatch: any, getState: any) => {
        const  {response, success} =  await getHttp(buildUrlClient('selectedServices'), params)
        if (success) {
            const {data} = response;
            const listService = await getListService(data, countServices);
            const serviceSelected =  await getServiceSelected(listService) as any
            const {id,independent} = provider as any
            const params = {
                id,
                date: selectedDate,
                independent,
                type: serviceSelected.typeId,
                min_time: serviceSelected?.minTime,
            };
            dispatch(setLoading({loading: false}));
            dispatch(setServicesCalendar({services: listService}))
            dispatch(getHourByDay(params, selectedDate, listService));
        }
    }
}

export const getHourByDay = (params: any, dateSelected: string, services: any = []) => {
    return async (dispatch: any, getState: any) => {
        const  {response, success, errors} =  await getHttp(buildUrlClient('appointmentSchedule'), params)
        if (success) {
            const hours =  await setStateHours(response, dateSelected);
            const checkHours = await hourCheckedByDay(hours, services);
            dispatch(setHoursByDay({hours: checkHours}))
        }
        dispatch(setLoading({loading: false}))
    }
}

export const onCalendar = (payment: any, stepper: string) => {
    return async (dispatch: any, getState: any) => {
        const {appointments, independent,services} = payment;
        const servicesItem = await  getServicesBody(services);
        const customBody = {
            appointmentId: null,
            independent: independent,
            itemService: servicesItem
        }
        const {response, success, errors} = await postHttp(buildUrlClient('appointmentService'), customBody);
        if (success) {
            dispatch(setLoading({loading: false}));
            dispatch(setAppointments({appointments: response, stepper: stepper}));
        }
    }
}

export const getAppointments =  (ids: any, promotion: PromotionModel) => {
    return async (dispatch: any, getState: any) => {
        if (promotion.id != undefined) {
            await promotionApply(ids, promotion)
        } else {
            /**
             * no aplica los descuentos globales
             */
        }
        const {response, success} = await getHttp(buildUrlClient(`appointment/${ids}`));
        if (success) {
            dispatch(setAppointmentList({appointmentList: response}))
            // await calculateCost();
            // discountMount();
        }
    }
}
export const getCodeApplyAppointments =  (ids: any, codeDiscount: string) => {
    return async (dispatch: any, getState: any) => {
        if (codeDiscount != undefined) {
            await codeApply(ids, codeDiscount)
        }
        const {response, success} = await getHttp(buildUrlClient(`appointment/${ids}`));
        if (success) {
            dispatch(setAppointmentList({appointmentList: response}))
            // await calculateCost();
            // discountMount();
        }
        dispatch(setLoading({loading: false}));
    }
}
const codeApply = async (ids: any, codeDiscount: string) => {
    let body = {
        code: codeDiscount,
        appointment_ids: ids,
    };
    const {response, success, errors} = await postHttp('applyCoupon', body);
    if (success) {

    }
}
const promotionApply = async (ids: string, promotion: PromotionModel) => {
    const body = {
        id: promotion.id,
        appointment_ids: ids,
        percentage_rate: promotion.discount_percentage
    }
    if ( promotion?.discount_percentage != undefined && promotion?.discount_percentage > 0 ) {
        const {response, success, errors} = await postHttp('applyPromo', body);
        if (success) {
        }
    }
}

export const deleteAppointmentByIds =  (ids: any) => {
    return async (dispatch: any, getState: any) => {
        const {response, success} = await deleteHttp(buildUrlClient('appointment'), ids);
        if (success) {
        }
    }
}
export const onConfirm = (payment: any, stepper: string) => {
    return async (dispatch: any, getState: any) => {
        const {appointments, independent,services, paymentMethod, appointmentList} = payment;
        const  currentUser = await getCurrentUser();
        const {result} = appointmentList
        const ids = appointments.map((item: any) => item.appointment_id).toString();
        let body = {
            appointments_id: ids,
            client_id: currentUser.id,
            servidor_id: 1,
            type_amount: paymentMethod,
            number_amount: '',
            amount: result?.total_cost,
            transport_cost: result?.transport_cost,
            discount_type: '',
            discount_rate: 0,
            coupon: null,
            account_exchange: result?.exchange_amount,
            bonu_valid: false,
            shared_code: '',
            user_share_code_id: '',
        };
        const {response, success, errors} = await postHttp(
            buildUrlClient('paymentAppointments'),
            body,
        );
        if (success) {
            const {payment, qr, appointments} = response;
            dispatch(setLoading({loading: false}));
            dispatch(setConfirm({paymentId: payment?.id, qr: qr, appointmentIds: ids, confirmSchedule: true}));
        } else {
            dispatch(setLoading({loading: false}));
        }
    }
}
