import {useEffect, useState} from "react";
import {deleteHttp, getHttp, putHttpOnly} from "../../api/HttpClient";
import {UseListHock} from "../../hooks/useListHock";
import ReactPaginate from "react-paginate";
import {initialStateResult} from "./AddressPage";
import {buildUrlBase} from "../../utils/BuildUrlBase";

interface Props {
  onPress: Function;
}
export const ContentList = ({onPress}: Props) => {

  const {list, removeItem, getDataList, addParams, total, page} = UseListHock('address');

  useEffect(() => {
    (async () => await getDataList(1))();
  }, []);
const onChecked = async (item: any) => {
  if (item.selected == 0) {
      const {response, success, message} = await putHttpOnly(
          'address/check',
          item.id,
      );
      if (success) {
        await getDataList(page)
      }
  } else {
    window.alert('La dirección predeterminada no se puede eliminar.')
  }
}
const onDelete = async (item: any) => {
  if (item.selected == 0) {
    const confirm  = window.confirm("¿ Estás seguro de eliminar ?");
    if (confirm) {
      const {response, success} = await deleteHttp(buildUrlBase('address'), item.id);
      if (success) {
        removeItem(item.id);
      }
    }
  } else {
    window.alert('La dirección predeterminada no se puede eliminar.')
  }
}
const onPageChange = async (page: any) => {
  await getDataList(page.selected + 1)
}
  // const loadData = async () => {
  //   const {response, success} = await getHttp('');
  // }
  return <div className="page-content-wrapper">
        <div className="row mb-3">
          <div className="col-12 d-sm-flex justify-content-between align-items-center">
            <h1 className="h3 mb-2 mb-sm-0">Mis direcciones </h1>
            <button className="btn btn-sm btn-primary mb-0" onClick={() => onPress({...initialStateResult, action: 'register'})}>Create</button>
          </div>
        </div>
    <div className="card bg-transparent border">
      <div className="card-header bg-light border-bottom">
        <div className="row g-3 align-items-center justify-content-between">
          <div className="col-md-8">
            <form className="input-group" >
              <input className="form-control border-secondary" type="search"
                     placeholder="Buscar..." aria-label="Search" />
              <button className="btn btn-success m-0" type="button">Buscar</button>
            </form>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive border-0 rounded-3">
          <table className="table table-dark-gray align-middle p-4 mb-0 table-hover">
            <thead>
            <tr>
              <th scope="col" className="border-0 rounded-start">Departamento</th>
              <th scope="col" className="border-0">Dirección</th>
              <th scope="col" className="border-0">Nro. Casa</th>
              <th scope="col" className="border-0">Mas Referencias</th>
              <th scope="col" className="border-0">Predeterminado</th>
              <th scope="col" className="border-0 rounded-end">Action</th>
            </tr>
            </thead>
            <tbody>
            {list.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center position-relative">
                      <h6 className="table-responsive-title mb-0 ms-2">
                        <p className="stretched-link">{item?.department?.name}</p>
                      </h6>
                    </div>
                  </td>
                  <td>
                    <h6 className="mb-0 fw-light">{item?.street}</h6>
                  </td>
                  <td>
                    {item.number}
                  </td>
                  <td>   {item.reference_detail}</td>
                  <td>
                    <input className="form-check-input"
                           type="checkbox"
                           checked={item.selected == 1 ? true : false}
                           onChange={() => onChecked(item)} />
                  </td>
                  <td>
                    <button className="btn btn-sm btn-success me-1 mb-1 mb-md-0" onClick={() => onPress({...initialStateResult, action: 'edit', id: item.id})}>Edit</button>
                    <button className="btn btn-sm btn-danger mb-0" onClick={() => onDelete(item)}>Delete</button>
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer bg-transparent pt-0">
        <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
          <p className="mb-0 text-center text-sm-start">Total páginas {total}</p>
        <ReactPaginate
            breakLabel="..."
            nextLabel="Sigiente >"
            onPageChange={(page) => onPageChange(page) }
            pageRangeDisplayed={5}
            pageCount={total}
            previousLabel="< Anterior"
            renderOnZeroPageCount={null}
            containerClassName={"pagination pagination-sm pagination-primary-soft d-inline-block d-md-flex rounded mb-0"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
        />
        </div>
      </div>

    </div>
  </div>
}
