import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {STEPPERS} from "../utils/Constants";
import {getServices} from "../store/thunks/servicesThunk";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus , faMinus} from '@fortawesome/free-solid-svg-icons'
import {Counter} from "../models/Counter";
import {setCounters, setServices} from "../store/slices/appointmentSlice";
import {getHttp} from "../api/HttpClient";
import {buildUrlBase} from "../utils/BuildUrlBase";
import {SpinnerApp} from "./SpinnerApp";
interface Props {
  userId: string | undefined;
  promotionId: string | undefined;
}
export const Services = ({userId,promotionId}: Props) => {
  const dispatch = useAppDispatch()
  const {stepper, provider, services,payment, promotion, isLoading} = useAppSelector((state: RootState) => state.appointment) as any;
  useEffect(() => {
    (async () => await loadData())();
  },[]);
  const loadData = async () => {

      // item.id, selectedTypeId, promoId

      // dispatch(getServices(params))
    // const {response, success} = await getHttp(buildUrlBase('servicesFilter'), params);
    // if (success) {
    //   dispatch(setServices({services: response}));
    // } else {
    //   dispatch(setServices({services: []}));
    // }
  }
  const onMinus = (service: any) => {
    let {counters} = payment;
    if (service) {
      let findItem = counters.find((item: Counter) => item.serviceId === service.id);
      if (findItem) {
        if (findItem.count > 0) {
          findItem = {...findItem, count: findItem.count - 1}
          let arrayCount: Counter[] = [];
          for (let k in counters) {
            const it = counters[k];
            if (it.countId == findItem.countId) {
              arrayCount.push(findItem);
            } else {
              arrayCount.push(it)
            }
          }
          dispatch(setCounters({counters: arrayCount}));
        }
      }
    }
  }
  const onAdd = (service: any, item: any) => {
    let {counters} = payment;
    let findItem = counters.find((item: Counter) => item.serviceId === service.id);
    if (findItem) {
      // findItem.count = findItem.count + 1;
      findItem = {...findItem, count: findItem.count + 1}
      const i = counters.findIndex((x: Counter) => x.serviceId === service.id)
      if ( i > -1) {
        let arrayCount: Counter[] = [];
        for (let k in counters) {
          const it = counters[k];
          if (it.countId == findItem.countId) {
            arrayCount.push(findItem);
          } else {
            arrayCount.push(it)
          }
        }
        dispatch(setCounters({counters: arrayCount}));
      }
    } else {
      const yep: Counter = {serviceId: service.id,typeId: item.id, count: 1};
      dispatch(setCounters({counters: [...counters, yep]}));
    }

  }
  const getCounter = (id: number) => {
    const {counters} = payment;
    const findItem = counters.find((item: Counter) => item.serviceId == id);
    if (findItem) {
      return findItem.count;
    }
    return 0;
  }
const onSearch = () => {

}
  return <div className="col-12 mb-xl-0">
    <div className={'row text-center'}>
      {isLoading && <div className="span6" style={{float: 'none', margin: '0 auto',}}>
        <SpinnerApp loading={isLoading} />
      </div>}
    </div>
    <form className="input-group">
      <input className="form-control border-secondary" type="search"
             placeholder="Buscar..." aria-label="Search" />
      <button className="btn btn-success m-0" type="button" onClick={() => onSearch()}>Buscar</button>
    </form>
    {services?.map((item: any, index: number) => (
            <div className="card  h-100" key={index}>
              <div className="card-header d-flex justify-content-start align-items-center ">
                <div className="icon-lg avatar avatar-xxl text-info">
                  {/*<i className="fas fa-user-tie fs-5"></i>*/}
                  <img className="avatar-img app-background"  src={item.small} />
                </div>
                <h6 className={'p-2'}>{item.name}</h6>
              </div>
              <div className="card-body">
                {item?.service.map( (service: any, key: number) => (
                     <div key={key + index}>
                      <div className="d-sm-flex justify-content-between align-items-center" >
                        <div className="d-sm-flex">
                          <div className="ms-0 ms-sm-2 mt-2 mt-sm-0">
                            <ul>
                              <li className={'list-group-item'}>
                                <h6 className="mb-0"><p className="stretched-link">{service.name}</p></h6>
                              </li>
                              <li className={'list-group-item'}>
                                <span className="h6 fw-light mb-0 fw-bold" > Precio: Bs. </span><span className="h6 fw-light mb-0 fw-bold"
                                      style={{
                                        textDecorationLine: promotion?.discount_percentage > 0  ? 'line-through' : 'none'
                                      }}>{service.cost}</span>
                              </li>
                              {promotion?.discount_percentage > 0 &&
                                  <li className={'list-group-item'}>
                                <span className="h6 fw-light mb-0 fw-bold">Precio: Bs.  { parseFloat(service.cost) - (parseFloat(promotion?.discount_percentage) * parseFloat(service.cost))}</span>
                                  </li>

                              }

                              <li className={'list-group-item'}>
                                <i className="far fa-clock text-danger me-2"></i>Tiempo estimado : {service.min_time + ' - '+ service.max_time} min.
                              </li>
                              <li className={'list-group-item'}>
                                <p className="mb-0">{service.description}</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="mb-0 text-nowrap">
                          <button className="btn btn-danger-soft  btn-round me-1 mb-1 mb-md-0 " onClick={() => onMinus(service)}>
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                          <button className="btn btn-secondary-soft  btn-round me-1 mb-1 mb-md-0" disabled={true}>
                            <p>{getCounter(service.id)}</p>
                          </button>
                          <button className="btn btn-success-soft btn-round me-1 mb-1 mb-md-0" onClick={() => onAdd(service, item)}>
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>
                      </div>
                      <hr/>
                    </div>
                ))}
              </div>
            </div>
    ))}
 </div>
}
