export const LoginRules = {
  email: {
    presence: {
      allowEmpty: false,
      message: '^El Correo electrónico es requerido',
    },
    email: {
      message: '^Correo electrónico inválido',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: '^La contraseña  es requerido',
    },
  },
};
