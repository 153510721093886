import {Work} from "./Work";
import {Comment} from "./Comment";
interface Props {
    userId: string | undefined;
    independent: string | undefined
}
export const About = ({userId, independent}: Props) => {
  return <>
        <ul className="nav nav-tabs mb-3">
          <li className="nav-item"><a className="nav-link active" data-bs-toggle="tab" href="#tab-1-1"> Trabajos </a></li>
          <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab-1-2"> Comentarios </a></li>
       </ul>
        <div className="tab-content">
            <div className="tab-pane show active" id="tab-1-1">
              <Work userId={userId} />
            </div>
            <div className="tab-pane" id="tab-1-2">
              <Comment userId={userId} independent={independent} />
            </div>

        </div>
  </>
}
