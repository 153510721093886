export const ForgotRules = {
    password: {
        presence: {
            allowEmpty: false,
            message: '^La contraseña en requerida',
        },
        length: {
            minimum: 5,
            message: '^La contraseña debe contener 5 caracteres como mínimo',
        },
    },
    confirmPassword: {
        confirmPassword: {
            equality: {
                attribute: 'password',
                message: '^La contraseña no coincide',
            },
        },
    },
};
