import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface FeaturedState {
    page: number,
    featured: any[],
    next_page?: null,
    isLoading?: boolean
}

const initialState: FeaturedState = {
    page: 0,
    featured: [],
    next_page: null,
    isLoading: false,
}

export const featuredSlice = createSlice({
    name: 'featured',
    initialState,
    reducers: {
        isLoading: (state, action) => {
            state.isLoading = action.payload.isLoading;
        },
        setFeatured: (state,action: PayloadAction<FeaturedState>) => {
            state.isLoading = false;
            state.featured =  action.payload.page == 1? action.payload.featured : [...state.featured, ...action.payload.featured];
            state.page = action.payload.page;
            state.next_page = action.payload.next_page;
        },
    },
})

// Action creators are generated for each case reducer function
export const { isLoading, setFeatured } = featuredSlice.actions
