import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useGeolocated} from "react-geolocated";
import { GoogleMap, LoadScript, MarkerF, useJsApiLoader} from "@react-google-maps/api";
import {GOOGLE_POSITION, Key} from "../utils/Constants";
import Autocomplete from "react-google-autocomplete";
import * as Constants from "constants";
import {AddressPage} from "../ui/address/AddressPage";
import {AddressChange} from "../ui/appointment/AddressChange";
interface Props {
    visible: boolean,
    onClose: Function,
    fullscreen: boolean,
}
export const AddressModal = ({visible, fullscreen = true, onClose}: Props) => {

    return <Modal show={visible} fullscreen={true} onHide={() =>onClose()}  size="xl"  >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <AddressChange />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => onClose()}>
                Cerrar
            </Button>
            <Button variant="primary" onClick={() => onClose()}>
                Confirmar
            </Button>
        </Modal.Footer>
    </Modal>
};
