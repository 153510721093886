import {getCurrentDate} from "./getCurrentDate";
import {getCurrentTime} from "./getCurrentTime";
import {getDateAddHourCurrentDate} from "./getDateAddHourCurrentDate";
import {getTimeAddHourCurrentTime} from "./getTimeAddHourCurrentTime";

export const setStateHours = async (hours: any, dateSelected: string) => {
    const currentDateNow = await getCurrentDate();
    const currentDateAddHour = await getDateAddHourCurrentDate(1);
    const currentTimeAddHour = await getTimeAddHourCurrentTime(1);
    const currentTime = await getCurrentTime();

    return  hours.map((item: any) => {
        if (currentDateAddHour === dateSelected) {
            if (item.time_ini.toString() <= currentTimeAddHour.toString()) {
                return {
                    ...item,
                    assigned: 0,
                    enabled:  0
                }
            }
        } else {
            if (dateSelected == currentDateNow) {
                return {
                    ...item,
                    assigned: 0,
                    enabled:  0
                }
            }
        }
        return {
            ...item
        }
    })
}
