import {configureStore} from '@reduxjs/toolkit'
import {counterSlice} from "./slices/counterSlice";
import {featuredSlice} from "./slices/featuredSlice";
import {typesSlice} from "./slices/typesSlice";
import {departmentsSlice} from "./slices/departmentsSlice";
import {providerSlice} from "./slices/providerSlice";
import {authSlice} from "./slices/authSlice";
import {appointmentSlice} from "./slices/appointmentSlice";
import {confirmSlice} from "./slices/confirmSlice";
import {filterSlice} from "./slices/filterSlice";
export const store = configureStore({
    reducer: {
        counter: counterSlice.reducer,
        featured: featuredSlice.reducer,
        filter: filterSlice.reducer,
        types: typesSlice.reducer,
        departments: departmentsSlice.reducer,
        providers: providerSlice.reducer,
        auth: authSlice.reducer,
        appointment: appointmentSlice.reducer,
        confirm: confirmSlice.reducer,
    },
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
