import {getSessionAppName, getSessionParent} from "../utils/Constants";

export const saveCurrentUser = async (user: any) => {
   return  window.localStorage.setItem(getSessionAppName(), JSON.stringify(user));
}

export const getCurrentUser = async () => {
    try {
        return JSON.parse(window.localStorage.getItem(getSessionAppName()) || "");
    }catch (e) {
        return ""
    }
}

export const clearCurrentUser = async () => {
   return window.localStorage.removeItem(getSessionAppName());
}

export const saveParentSession = async (userId: string) => {
    return  window.localStorage.setItem(getSessionParent(), JSON.stringify(userId));
}

export const getParentSession = async () => {
    try {
        return JSON.parse(window.localStorage.getItem(getSessionParent()) || "");
    }catch (e) {
        return ""
    }
}

export const clearParentSession = async () => {
    return window.localStorage.removeItem(getSessionParent());
}
