import {Header} from "../../layout/header";
import {Spinner} from "react-bootstrap";
import {Footer} from "../../layout/footer";
import {useAppSelector} from "../../hooks/hook";
import {RootState} from "../../store/store";
import {PromotionContent} from "./PromotionContent";
import {useNavigate} from "react-router-dom";

export const PromotionPage = () => {
  const navigate = useNavigate();
  const {isLoading} = useAppSelector((state: RootState) => state.providers);
  return <>
    <Header />
    <div className="container">
      <nav  aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href={'/'}>Inicio</a></li>
          <li className="breadcrumb-item active" aria-current="page">Promociones</li>
        </ol>
      </nav>
    </div>

    {isLoading &&  <div className={'container text-center'}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">...</span>
      </Spinner>
    </div>}
    <div className={'container'}>
      <PromotionContent  />
    </div>
    <Footer />
  </>
}
