import {getHttp} from "../../api/HttpClient";
import {buildUrlClient} from "../../utils/BuildUrlClient";
import {isLoading, setFeatured} from "../slices/featuredSlice";
import {CONSTANTS} from "../../utils/Constants";
import {setProviders} from "../slices/providerSlice";
export const getProviders = (page: number = 1, searchText: string = "", typeId: number = 0, departmentId: number = 0  ) => {
    return async (dispatch: any, getState: any) => {
        dispatch(isLoading({isLoading: true}))
        const  {response, success} =  await getHttp(buildUrlClient('professionalsSalons'),
            {
            page: page,
            serviceName: searchText,
            latitude: CONSTANTS.MAP_LATITUDE,
            longitude: CONSTANTS.MAP_LONGITUDE,
            radius: 14,
            date: '',
            hourIni: '',
            typeId: typeId,});

        if (success) {
            const {data, next_page_url} = response;
            dispatch(setProviders({providers: data, page: page , next_page: next_page_url, isLoading: false }))
        } else {
            dispatch(isLoading({isLoading: false}))
        }
    }
}
