import React, {useContext, useEffect, useState} from "react";
import {validateHelper} from "../helpers/validateHelper";
import {LoginRules} from "../rules/LoginRules";
import {ShowError} from "./showError";
import {onLogin, onLoginFace} from "../store/thunks/authTrunk";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {postHttp} from "../api/HttpClient";
import { signInWithPopup, getAuth, onAuthStateChanged, FacebookAuthProvider } from "firebase/auth";
import appFirebase from "../config/Firebase";


export const Login = () => {
  const auth = getAuth(appFirebase);
  const dispatch = useAppDispatch();
  const {message} = useAppSelector((state: RootState) => state.auth);
  const [data, setData] = useState({
    email: '',
    password: ''
  });
  const [messages, setMessages] = useState({
    email: '',
    password: '',
  });

  const [dataForgot, setDataForgot] = useState({
    email: '',
  });
  const [messagesForgot, setMessagesForgot] = useState({
    email: '',
  });

  const [option, setOption] = useState('login');

  useEffect(() => {
    (async () => await loadState())();
  },[]);
  const loadState = () => {

  }
  const onChange = (event: any, field: string) => {
    // @ts-ignore
    data[field] = event.target.value;
    setData({...data});
    onValidate(event.target.value, field);
  }
  const onValidate = (value: any, field: string) => {
    const inputRules = getRulesByField(field);
    const resultValidate = validateHelper(field, value, inputRules, data);
    // @ts-ignore
    messages[field] = resultValidate;
    setMessages({...messages});
  };
  const getRulesByField = (field: string) => {
    for (const [key, value] of Object.entries(LoginRules)) {
      if (key === field) {
        return value;
      }
    }
    return [];
  }
  const checkValidate = () => {
    for (const [key, value] of Object.entries(data)) {
      onValidate(value, key);
    }
  }
  const isValid = () => {
    let count = 0;
    for (let [key, value] of Object.entries(messages)) {
      if (value) {
        count = count + 1;
      }
    }
    return count;
  };
  const onSocialFacebook = () => {
    // dispatch(setSocialLogin({socialLogin: 'facebook'}));
    // dispatch(setShowModal({showModal: false}))
  }
  const onFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider).then((result: any)=>{
      const {user, _tokenResponse} = result;
      const body = {
        name: _tokenResponse.firstName,
        last_name: _tokenResponse.lastName,
        email: user.email,
        provider_login: 'firebase-facebook',
        provider_uid: user.uid,
        mobile:  ''
      };
      onLoginFacebook(body);
    }).catch((err)=>{
      alert(err.toString())
    }).finally(()  => {

    })
  }
  const onLoginFacebook = (body: any) => {
    dispatch(onLoginFace(body));
  }
  const onSubmit = async () => {
    checkValidate();
    if (isValid() == 0) {
       dispatch(onLogin(data));
    }
  }
  const onForgot = () => {
    setOption('forgot');
  }

  const checkValidateForgot = () => {
    for (const [key, value] of Object.entries(dataForgot)) {
      onValidateForgot(value, key);
    }
  }
  const isValidForgot = () => {
    let count = 0;
    for (let [key, value] of Object.entries(messagesForgot)) {
      if (value) {
        count = count + 1;
      }
    }
    return count;
  };
  const onSubmitForgot = async () => {
    checkValidateForgot();
    if (isValidForgot() == 0) {
      const body = {
        email: dataForgot.email,
      };
      const {response, success, message} = await postHttp('/password/email', body);
      if (success) {
        dataForgot.email = '';
        setDataForgot({...dataForgot});
        setOption('login');
        alert(message);
      } else {
        alert(message);
      }
    }

  }
  const onForgotLogin = () => {
    setOption('login');
  }
  const onChangeForgot = (event: any, field: string) => {
    // data[field] = event.target.value;
    setDataForgot({...dataForgot, email: event.target.value});
    onValidateForgot(event.target.value, field);
  }
  const onValidateForgot = (value: any, field: string) => {
    const inputRules = getRulesByField(field);
    const resultValidate = validateHelper(field, value, inputRules, data);
    // @ts-ignore
    messagesForgot[field] = resultValidate;
    setMessagesForgot({...messagesForgot});
  };

  return <div>
    <div className="col-lg-12">
      <div className="card  p-4 p-sm-5 position-relative">
        {message && <div className={"alert alert-danger"}> {message}</div>}
        {option == 'login' ? <form className="row g-3 position-relative">
          <div className="col-12">
            <label className="form-label">E-mail *</label>
            <input type="text" className="form-control"
                   aria-label="Email"
                   value={data.email}
                   onChange={event => onChange(event, 'email')}/>
            <ShowError message={messages.email} />
          </div>
          <div className="col-12">
            <label className="form-label">Contraseña *</label>
            <input type="password"
                   className="form-control"
                   value={data.password}
                   onChange={event => onChange(event, 'password')}/>
            <ShowError message={messages.password} />
          </div>

          <div className="col-12 text-center">
            <button type="button" className="btn btn-success w-50" onClick={() => onSubmit()}>Iniciar</button>
          </div>
              <div className="d-flex justify-content-end p-1 ">
                <div className="text-primary-hover align-items-end">
                  <a onClick={() => onForgot()} className="text-secondary">
                    <u>¿Has olvidado tu contraseña?</u>
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="position-relative my-4">
                  <hr/>
                    <p className="small position-absolute top-50 start-50 translate-middle bg-body px-5">Or</p>
                </div>
                {/*col-xxl-12 d-grid*/}
                <div className="col-12 text-center">
                  <button type="button" onClick={() => onFacebook()} className="btn bg-facebook mb-2 mb-xxl-0"><i
                      className="fab fa-fw fa-facebook-f text-white me-2"></i>Inicia sesión con Facebook
                  </button>
                </div>
              </div>
        </form> :
            <>
              <h1 className="fs-2">¿Has olvidado tu contraseña?</h1>
              <h5 className="fw-light mb-4">Para cambiar una nueva contraseña, ingrese su dirección de correo electrónico a continuación.</h5>
              <form className="row g-3 position-relative">
                <div className="col-12">
                  <label className="form-label">E-mail *</label>
                  <input type="text" className="form-control"
                         aria-label="Email"
                         value={dataForgot.email}
                         onChange={event => onChangeForgot(event, 'email')}/>
                  <ShowError message={messagesForgot.email} />
                </div>
                <div className="col-12 text-center">
                  <button type="button" className="btn btn-success w-50" onClick={() => onSubmitForgot()}>Enviar</button>
                </div>
              </form>
              <div className="d-flex justify-content-center p-1 ">
                <div className="text-primary-hover align-items-end">
                  <a onClick={() => onForgotLogin()} className="text-secondary">
                    <u className={'h6'}>Ir, a Inicio de sesión.</u>
                  </a>
                </div>
              </div>
            </>
            }
      </div>
    </div>
  </div>
}
