import validatejs from 'validate.js';
export const validateHelper = (
  fieldName: any,
  value: any,
  rules: any,
  model: any,
) => {
  let result = null;
  if (fieldName === 'confirmPassword') {
    result = validatejs(
      {password: model.password, confirmPassword: value},
      rules,
    );
  } else {
    result = validatejs({[fieldName]: value}, {[fieldName]: rules});
  }
  if (result) {
    return result[fieldName][0] ? result[fieldName][0] : '';
  }
  return '';
};
