import {useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {useEffect, useState} from "react";
import {getHttp} from "../api/HttpClient";
import {buildUrlBase} from "../utils/BuildUrlBase";
import {Card} from "react-bootstrap";
interface Props {
  userId: string | undefined;
}
export const Work = ({userId}: Props) => {
  const [album, setAlbum] = useState([]);
  useEffect(() => {
    (async () => await loadAlbum())();
  },[]);

  const loadAlbum = async () => {
    const params = {userId: userId, page: 1};
    const {response, success} = await getHttp(buildUrlBase('worksByUser'), params);
    if (success) {
      setAlbum(response);
    }
  };
  return <>
        {album.map((item: any, index: number) => (
            <div key={index}>
              <p>{item.name}</p>
              <div className={'row'}>
              {item.works.map((work: any, key: number) => (
                  <div className={'col-6 p-1'} key={key+index}>
                    <Card style={{ width: '100%' }}>
                      <Card.Img src={work.resizedImageLink} />
                    </Card>
                  </div>
              ))}
              </div>
            </div>
        ))}
    </>
}
