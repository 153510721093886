import {useEffect, useState} from "react";
import logo from "./../assets/images/logo.png"
import { useAppSelector, useAppDispatch } from '../hooks/hook'
import {getDepartments} from "../store/thunks/departmentThunk";
import Select from "react-dropdown-select";
import {RootState} from "../store/store";
import {ModalAccount} from "../compoments/ModalAccount";
import {onLogout} from "../store/thunks/authTrunk";
import {setShowModal} from "../store/slices/authSlice";
import {setDepartmentId, setSearchText} from "../store/slices/filterSlice";
import {getFeatured} from "../store/thunks/featuredThunk";
import {getProviders} from "../store/thunks/providerThunk";
import {useNavigate} from "react-router-dom";
import {ShowNotification} from "../compoments/ShowNotification";
export const HeaderBasic = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const {departments} = useAppSelector((state: RootState) => state.departments);
    const {searchText, typeId, departmentId} = useAppSelector((state: RootState) => state.filter);
    const {status, showModal, session} = useAppSelector((state: RootState) => state.auth);
    const [showProfile, setShowProfile] = useState(false);
    const callback = ($event: any) => {
      if (showProfile) {
          setShowProfile(false);
          $event.stopPropagation();

      }
    }
    useEffect(() => {
        const handleClick = ($event: any) => {
            callback($event);
        };
        document.addEventListener('click', handleClick);
        return () => {
        document.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
        (async () => await loadData())();
    }, []);
    const loadData = () => {
        dispatch(getDepartments())
    }
    const onSelected = (element: any) => {
        dispatch(setDepartmentId({departmentId: element.id}))
    }
    const onProfile = ($event: any) => {
        $event.stopPropagation();
      setShowProfile(true)
    }
    const logOut = () => {
      dispatch(onLogout())
     return navigate(`/`);
    }

    const onAccount = () => {
        dispatch(setShowModal({showModal: true}))
    }
    const getFullName = () => {
        let fullName = 'A';
      if (session) {
          fullName = session?.name?.substring(0,1) + session?.last_name?.substring(0,1);
      }
      return fullName?.toUpperCase();
    }

    const onCloseModal = () => {
      dispatch(setShowModal({showModal: false}))
    }
    const onSubmit = () => {

    }
    const onSearch = () => {
        dispatch(getFeatured(1, searchText, typeId, departmentId));
        dispatch(getProviders(1, searchText, typeId, departmentId));
    }
    const onChangeText = (text: any) => {
      dispatch(setSearchText({searchText: text.target.value}))
    }
    const onAbort = () => {
    }
    return <>
        <header className="navbar-light navbar-sticky">
               <nav className="navbar navbar-expand-xl z-index-9">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img className="light-mode-item navbar-brand-item" src={logo} alt="logo" />
                    </a>
                    {/*Select departments*/}
                    <div className="navbar-collapse collapse">
                        <div className={'w-100'}>
                        </div>

                    </div>

                    <div className="navbar-collapse collapse" >
                        <div className="col-12">
                            <div className="nav my-3 my-xl-0 px-4 flex-nowrap align-items-center">
                                <div className="nav-item w-100">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
        <ModalAccount
            show={showModal}
            onHide={() => onCloseModal()}
        />
    </>
}
