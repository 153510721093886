import {getHttp} from "../../api/HttpClient";
import {buildUrlClient} from "../../utils/BuildUrlClient";
import {setTypes} from "../slices/typesSlice";
import {setServices} from "../slices/appointmentSlice";
import {isLoading, setFeatured} from "../slices/featuredSlice";
import {CONSTANTS} from "../../utils/Constants";

export const getServices = (params: any) => {
    return async (dispatch: any, getState: any) => {
        const  {response, success} =  await getHttp(buildUrlClient('servicesFilter'), params)
        if (success) {
            dispatch(setServices({services: response}));
        } else {
            dispatch(setServices({services: response}));
        }
    }
}

export const getByServices = (page: number = 1, searchText: string = "", typeId: number = 0, departmentId: number = 0 ) => {
    return async (dispatch: any, getState: any) => {
        dispatch(isLoading({isLoading: true}))
        const  {response, success} =  await getHttp(buildUrlClient('hotelServices'),
            {
                page: page,
                serviceName: searchText,
                latitude: CONSTANTS.MAP_LATITUDE,
                longitude: CONSTANTS.MAP_LONGITUDE,
                radius: 14,
                date: '',
                hourIni: '',
                typeId: typeId,})
        if (success) {
            const {data, next_page_url} = response;
            dispatch(setFeatured({featured: data, page: page , next_page: next_page_url , isLoading: false}))
        } else {
            dispatch(isLoading({isLoading: false}))
        }
    }
}
