export const checkServicesAssigned = async (services: any[]) => {
    for (let key in services) {
        const itemService = services[key].service;
        for (let ky in itemService) {
            const service = itemService[ky];
            if (service.professionalId == 0) {
                return false;
            }
        }
    }
    return true;
}
