import {useAppDispatch} from "../hooks/hook";
import {useEffect} from "react";
import {getCurrentUser, getParentSession} from "../services/LocalService";
import {setCheckToken} from "../store/slices/authSlice";
interface Props {
    children: JSX.Element | JSX.Element[]
}
 export const AppState = ({ children }: Props) => {
     const dispatch = useAppDispatch();
     useEffect(() => {
         (async () => await checkToken())();
     }, []);
     const checkToken = async () => {
         const currentSession = await getCurrentUser();
         const parentId = await getParentSession();

         if (currentSession != '') {
             dispatch(setCheckToken({status: 'authenticated',  message: '', session: currentSession, parentId: parentId }));
         }
     }
   return <> {children} </>
 }
