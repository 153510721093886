export const AddressRules = {
  department_id: {
    presence: {
      allowEmpty: false,
      message: '^El campo es requerido',
    },
  },
  street: {
    presence: {
      allowEmpty: false,
      message: '^La dirección es requerida',
    },
  },
  number: {
    presence: {
      allowEmpty: false,
      message: '^El campo es requerido',
    },
  },
  latitude: {
    presence: {
      allowEmpty: false,
      message: '^ Agregue su dirección en google map',
    },
  },
  longitude: {
    presence: {
      allowEmpty: false,
      message: '^ Agregue su dirección en google map.',
    },
  },

  reference_detail: {
    presence: {
      allowEmpty: false,
      message: '^El campo es requerido',
    },
  },
};
