import {STEPPERS} from "../../utils/Constants";
import {checkServicesAssigned} from "./checkServicesAssigned";

export const getCheckStepperCondition = async (stepper: string, payment: any) => {
    const {services} = payment;
    switch (stepper) {
        case STEPPERS.CALENDAR:
            return await checkServicesAssigned(services);
        case STEPPERS.PAYMENT:
            return true;
        default:
            return false;
    }
}
