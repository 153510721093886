import {useState} from "react";
import {Header} from "../../layout/header";
import {Footer} from "../../layout/footer";
import {getCurrentUser} from "../../services/LocalService";
import {useAppDispatch, useAppSelector} from "../../hooks/hook";
import {onRemoveAccount} from "../../store/thunks/authTrunk";
import {RootState} from "../../store/store";
import {setShowModal} from "../../store/slices/authSlice";

export const RemovePage = () => {
  const dispatch = useAppDispatch()
  const [checked, setChecked] = useState(false);
  const {message} = useAppSelector((state: RootState) => state.auth);
  const goRemoveAccount = async () => {
    const  currentUser = await getCurrentUser();
    if (currentUser) {
      if (checked) {
        dispatch(onRemoveAccount({id: currentUser.id}))
      } else {
        alert('Seleccione el check');
      }
    } else {
      // eslint-disable-next-line no-restricted-globals
     const result = confirm('Inicie sesión');
      if (result) {
        dispatch(setShowModal({showModal: true}))
      }
    }
  }

  const handleChange = () => {
    setChecked(!checked);
  };

  return <div>
    <Header />
    <div className={'container'}>
      <div className="row">
        {message != '' && <div className="alert alert-success" role="alert">
          {message}
        </div>}
        <div className="card border bg-transparent rounded-3 mb-0">
          <div className="card-header bg-transparent border-bottom">
            <h3 className="card-header-title mb-0">Eliminar Cuenta</h3>
          </div>
          <div className="card-body">
            <h6>Nota</h6>
            <ul>
              <li> Si eliminas tu cuenta, se eliminarán tus datos de Bela2go.</li>
            </ul>
            <div className="form-check form-check-md my-4">
              <input className="form-check-input"
                     type="checkbox"
                     id="deleteAccountCheck"
                     checked={checked}
                     onChange={handleChange}/>
                <label className="form-check-label" >Sí, me gustaría eliminar mi cuenta.</label>
            </div>
            <button className={"btn btn-danger mb-0"} onClick={() => goRemoveAccount()}>Eliminar mi cuenta</button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
}
