import {Button} from "react-bootstrap";
import React, {FormEvent, useState, useEffect} from "react";
import {RegisterRules} from "../../rules/RegisterRules";
import {validateHelper} from "../../helpers/validateHelper";
import {ShowError} from "./../../compoments/showError";
import {postHttp} from "../../api/HttpClient";
import DatePicker from "react-datepicker";
import Select from "react-dropdown-select";
import {typePayment} from "../../data/Data";
// import Autocomplete from "react-google-autocomplete";
import { GoogleMap, LoadScript, Marker, MarkerF, Autocomplete } from '@react-google-maps/api';
import { useGeolocated } from "react-geolocated";
import {Header} from "../../layout/header";
import {Footer} from "../../layout/footer";
import {Key} from "../../utils/Constants";
export const RegisterPage = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const typeService = queryParams.get("tipo_servicio");
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(new Date());
    const [geoFirst, setGeoFirst] = useState(true);
    const [loading, setLoading] = useState(false);
    const [position, setPosition] = useState({lat: -17.786612, lng: -63.178121});
    useEffect(() => {
        (async () => loadLocation())();
    }, []);
    const [data, setData] = useState({
        name: '',
        last_name: '',
        email: '',
        mobile: '',
        street: '',
        latitude: '',
        longitude: '',
        reference_detail: '',
        date: date,
        hour_ini: time,
        type_payment: '',
    });
    const [errorRegister, setErrorRegister] = useState({
        name: '',
        last_name: '',
        email: '',
        mobile: '',
        street: '',
        latitude: '',
        longitude: '',
        reference_detail: '',
        hour_ini: '',
        date: '',
        type_payment: '',
    });
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });
    if (isGeolocationEnabled && isGeolocationAvailable) {
        if (coords) {
            const {latitude, longitude} = coords;
            if (geoFirst) {
                if (data.latitude == '') {
                    // eslint-disable-next-line no-restricted-globals
                 const result =   confirm('Su posición actual se le asignará a tu dirección?  ');
                 if (result) {
                     data['latitude'] = latitude.toString();
                     data['longitude'] = longitude.toString();
                     setData({...data});
                 }

                }
                setGeoFirst(false);
            }
            // data['latitude'] = latitude.toString();
            // data['longitude'] = longitude.toString();
            // setData({...data});
        }
    }
const loadLocation = () => {

}
    const onChange = (event: any, field: string) => {
        // @ts-ignore
        // data[field] = event.target.value;
        setData({...data, [field]: event.target.value});
        onValidate(event.target.value, field);
    }
    const onValidate = (value: any, field: string) => {
       const inputRules = getRulesByField(field);
       const resultValidate = validateHelper(field, value, inputRules, data);
        // @ts-ignore
       // errorRegister[field] = resultValidate;
        setErrorRegister({...errorRegister, [field]: resultValidate});
    };
       const getRulesByField = (field: string) => {
           for (const [key, value] of Object.entries(RegisterRules)) {
               if (key === field) {
                   return value;
               }
           }
           return [];
       }
       const resetData = () => {
        const initData =   {
               name: '',
               last_name: '',
               email: '',
               mobile: '',
               street: '',
               latitude: '',
               longitude: '',
               reference_detail: '',
               date: date,
               hour_ini: time,
               type_payment: 'Qr simple',
           }
         setData({
             ...initData,
         })
       }
    const saveRegister = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        checkValidate();
        setLoading(true);
        if (isValid() == 0) {
            const body = {
                name: data.name,
                last_name: data.last_name,
                email: data.email,
                mobile: data.mobile,
                street: data.street,
                latitude: data.latitude,
                longitude: data.longitude,
                reference_detail: data.reference_detail,
                hour_ini: data.hour_ini,
                date: data.date,
                type_payment: data.type_payment,
                type_service: typeService || '',
            }
            const {response, success, message, errors} = await postHttp('preAppointment', body);
            if (success) {
                alert('Se registro correctamente')
                setLoading(false);
                resetData()
            } else {
                setLoading(false);
            }
        } else {
            alert('Error revise el formulario');
            setLoading(false);
        }
    }
    const checkValidate = () => {
        for (const [key, value] of Object.entries(data)) {
            onValidate(value, key);
        }
    }
    const isValid = () => {
        let count = 0;
        for (let [key, value] of Object.entries(errorRegister)) {
            if (value) {
                count = count + 1;
            }
        }
        return count;
    };
       const setDateSelected = (dateSelected: any, field: string) => {
           // @ts-ignore
           data[field] = dateSelected;
           setData({...data});
           onValidate(dateSelected, field);
       }
       const optionChange = (input: any, field: string) => {
           // @ts-ignore
           data[field] = input[0].value;
           setData({...data});
           onValidate(input[0].value, field);
       }
       const onClickMap = (map: any, latitude: string, longitude: string ) => {
         const maplong =  map.latLng.lng();
         const maplat =  map.latLng.lat();
         if (map) {
             // @ts-ignore
             data[latitude] = map.latLng.lat();
             // @ts-ignore
             data[longitude] = map.latLng.lng();
             setData({...data});
             position.lng = map.latLng.lng();
             position.lat = map.latLng.lat();
             setPosition({...position});
             onValidate(map.latLng.lat(), 'latitude');
         }
       }
       const inputSearch = (place: any) => {
           if (place) {
               const {location} = place?.geometry;
               data['latitude'] = location.lat();
               data['longitude'] = location.lng();
               setData({...data});
               position.lng = location.lat();
               position.lat = location.lng();
               setPosition({...position})
               onValidate(location.lat(), 'latitude');
           }
       }

    return   <div>
        <Header />
        <div className={'container'}>
            <form onSubmit={(event) => saveRegister(event)}>
                <div className={'row'}>
                    <div className="col-md-6">
                        <label htmlFor="labelName" className="form-label">Nombre</label>
                        <input type="text"
                               className="form-control"
                               id="nameId"
                               value={data.name}
                               onChange={event => onChange(event, 'name')}/>
                        <div id="emailHelp" className="form-text">
                        </div>
                        <ShowError message={errorRegister.name} />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="labelName" className="form-label">Apellidos</label>
                        <input type="text"
                               className="form-control"
                               id="nameId"
                               value={data.last_name}
                               onChange={event => onChange(event, 'last_name')}/>
                        <div id="emailHelp" className="form-text">
                        </div>
                        <ShowError message={errorRegister.last_name} />
                    </div>
                </div>
                <div className={'row'}>
                    <div className="col-md-6">
                        <label htmlFor="labelName" className="form-label">Email</label>
                        <input type="text"
                               className="form-control"
                               id="nameId"
                               value={data.email}
                               onChange={event => onChange(event, 'email')}/>
                        <div id="emailHelp" className="form-text">
                        </div>
                        <ShowError message={errorRegister.email} />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="labelName" className="form-label">Nro. Celular</label>
                        <input type="text"
                               className="form-control"
                               id="nameId"
                               value={data.mobile}
                               onChange={event => onChange(event, 'mobile')}/>
                        <div id="emailHelp" className="form-text">
                        </div>
                        <ShowError message={errorRegister.mobile} />
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="labelName" className="form-label">Dirección</label>
                    <textarea
                        className="form-control"
                        id="nameId"
                        rows={2}
                        value={data.street}
                        onChange={event => onChange(event, 'street')}/>
                    <div id="emailHelp" className="form-text">
                    </div>
                    <ShowError message={errorRegister.street} />
                </div>
                <div className={'row'}>
                    <div style={{ height: '350px', width: '100%' }}>
                        <div className={'p-2'}>
                            {/*<Autocomplete*/}
                            {/*    apiKey={'AIzaSyC0Tjtfuu3jGLUBpzcwF5Vq80BIEkDl2Bw'}*/}
                            {/*    onPlaceSelected={(place: any) => inputSearch(place)}*/}
                            {/*/>*/}
                        </div>
                        <LoadScript
                            googleMapsApiKey={Key.google}
                        >
                            <GoogleMap
                                onClick={e => onClickMap(e, 'latitude', 'longitude')}
                                mapContainerStyle={{  width: '100%',
                                    height: '80%'}}
                                center={{ lat: position.lat, lng: position.lng }}
                                zoom={10}
                            >
                                {data.latitude != '' && <MarkerF label={''}
                                                                 position={{ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }} />}
                            </GoogleMap>
                        </LoadScript>
                    </div>
                    <ShowError message={errorRegister.latitude} />
                </div>

                <div className="row">
                    <label htmlFor="labelName" className="form-label">Referencias de su Dirección</label>
                    <textarea
                        className="form-control"
                        id="nameId"
                        rows={3}
                        value={data.reference_detail}
                        onChange={event => onChange(event, 'reference_detail')}/>
                    <div id="emailHelp" className="form-text">
                    </div>
                    <ShowError message={errorRegister.reference_detail} />
                </div>
                <div className={'row'}>
                    <div className="col-md-6">
                        <label htmlFor="labelName" className="form-label">Fecha para la cita:</label>
                        <DatePicker
                            selected={data.date}
                            className={'form-control'}
                            onChange={(date: any) => setDateSelected(date, 'date')} />
                        <ShowError message={errorRegister.date} />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="labelName" className="form-label">Hora inicio:</label>
                        <DatePicker
                            selected={data.hour_ini}
                            onChange={(date: any) => setDateSelected(date, 'hour_ini')}
                            showTimeSelect
                            className={'form-control'}
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"/>
                        <ShowError message={errorRegister.hour_ini} />
                    </div>
                </div>
                <div className={'row'}>
                    <div className="col-md-12">
                        <label htmlFor="labelName" className="form-label">Método de pago:</label>
                        <Select required
                                className={'form-control'}
                                options={typePayment}
                                onChange={(value: any) => optionChange(value,'type_payment')}
                                values={[]}/>
                        <ShowError message={errorRegister.hour_ini} />
                    </div>
                </div>
                <div className={'row'} style={{height: 50}}>
                    <div className={'offset-6'}>
                        { loading && <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>}
                    </div>

                </div>
                <div className={'row col-6 offset-3' } >
                    <Button variant="light"
                            disabled={loading}
                            style={{background: '#009b9f', color: 'white'}}
                            type="submit">Guardar </Button>
                </div>
            </form>
        </div>
        <Footer />
    </div>

}
