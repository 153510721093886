import {useRef, useState} from 'react';
import {getHttp} from '../api/HttpClient';

export const UseListHock = (url: string, filter = {}) => {
  const [list, setList] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const pageRef = useRef(1);
  const successRef = useRef(false);
  const paramsRef = useRef({});
  const nextPageRef = useRef(null);
  const totalPageRef = useRef(1);

  const getDataList = async (page = 1) => {
    setLoading(true);
    pageRef.current = page;
    const params = {
      page: pageRef.current,
      ...filter,
      ...paramsRef.current,
    };
    const {response, success} = await getHttp(url, params);
    successRef.current = success;
    if (success) {
      const {data, next_page_url, total, per_page} = response;
      nextPageRef.current = next_page_url;
      totalPageRef.current = Math.ceil(total / per_page);

      setList(data);
    }
    setIsFetching(false);
    setLoading(false);
  };

  const loadMore = () => {
    if (nextPageRef.current != null) {
      pageRef.current++;
      (async () => getDataList(pageRef.current))();
    }
  };
  const onReset = () => {
    pageRef.current = 1;
    nextPageRef.current = null;
    setIsFetching(true);
    (async () => getDataList(pageRef.current))();
  };
  const addParams = (params = {}) => {
    paramsRef.current = params;
  };
  const removeItem = (id: number) => {
    const newList = list.filter((el: any) =>  { return el.id != id; });
    setList([...newList]);
  };
  const setItems = (items: any[] = []) => {
    setList([...items]);
  };

  return {
    list,
    getDataList,
    onReset,
    loadMore,
    addParams,
    removeItem,
    setItems,
    isFetching,
    success: successRef,
    nextPage: nextPageRef.current,
    total: totalPageRef.current,
    page: pageRef.current,
    loading,
  };
};
