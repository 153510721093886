export const RegisterRules = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^El Nombre es requerido',
    },
    length: {
      maximum: 50,
      message: '^El Nombre debe contener 50 caracteres como máximo',
    },
  },
  surnames: {
    presence: {
      allowEmpty: false,
      message: '^El Apellido es requerido',
    },
    length: {
      maximum: 50,
      message: '^El Nombre debe contener 50 caracteres como máximo',
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: '^El Correo electrónico es requerido',
    },
    email: {
      message: '^Correo electrónico inválido',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: '^La contraseña es requerida',
    },
  },
  // confirmPassword: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^El Apellido es requerido',
  //   },
  //   equality: "password"
  // },

  confirmPassword: {
    confirmPassword: {
      equality: {
        attribute: 'password',
        message: '^La contraseña no coincide',
      },
    },
  },
};
