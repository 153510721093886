import {useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {UsePaginateHock} from "../hooks/usePaginateHock";
import {buildUrlBase} from "../utils/BuildUrlBase";
import {useEffect, useState} from "react";
import {buildUrlClient} from "../utils/BuildUrlClient";
interface Props {
    userId: string | undefined;
    independent: string | undefined;
}
export const Comment = ({userId, independent}: Props) => {
    const params = {
        independent: independent,
        user_id: userId,
    };
    const {list, onReset, loadMore, loading, getDataList, page} = UsePaginateHock(
        buildUrlClient('comments'),
        params);

    const [album, setAlbum] = useState([]);
    useEffect(() => {
        (async () => await getDataList(1))();
    },[]);

  return <>
      <ul className="list-unstyled ms-4" >
          {list.map((item: any, index: number) => (
              <div className="border-bottom" key={index}>

                  <div className="d-sm-flex justify-content-sm-between mb-3">
                      <div className="d-flex align-items-center">

                          <button className="btn btn-success btn-round me-1 mb-1 mb-md-0"
                                  data-bs-toggle="tooltip" data-bs-placement="top" title=""
                                  data-bs-original-title="Cliente" aria-label="Cliente"><i
                              className="bi bi-person"></i></button>
                          <div className="ms-2">
                              <h6 className="mb-0"><a href="#">{item.user_name} {item.user_last_name}</a></h6>
                              <small>{item.date}</small>
                          </div>
                      </div>
                  </div>
                  <p className="mb-2">{item.comment}</p>
              </div>
      ))}
      </ul>
    </>
}
