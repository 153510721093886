import {HourModel} from "../../models/HourModel";

export const assignedHour = async (hoursParam: any, hoursTempIds: number[]) => {
  let list = [];
  for (const hoursKey in hoursParam) {
    let item =  hoursParam[hoursKey];
    if (hoursTempIds.includes(item.hour_id)) {
      list.push({...item, assigned: 1});
    } else {
      list.push({...item});
    }
  }
  return list;
}
