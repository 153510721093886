import { createSlice } from '@reduxjs/toolkit'
export interface DepartmentsState {
    departments: any[]
}
const initialState: DepartmentsState = {
    departments: [],
}
export const departmentsSlice = createSlice({
    name: 'departments',
    initialState,
    reducers: {
        setDepartments: (state, action) => {
            state.departments = action.payload.departments
        },
    },
})
export const { setDepartments } = departmentsSlice.actions
