import {useNavigate} from "react-router-dom";

export const Breadcrumb = () => {
  const navigate = useNavigate();
  const goRedirect = (value: string) => {
    navigate(`/`);
  }
  return <nav  aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a onClick={() => goRedirect('home')}>Inicio</a></li>
          <li className="breadcrumb-item active" aria-current="page">Agendar</li>
        </ol>
  </nav>
}
