import React, {useState} from "react";
import { osName, OsTypes } from 'react-device-detect';
import {useParams} from "react-router-dom";
import {validateHelper} from "../../helpers/validateHelper";
import {ShowError} from "../../compoments/showError";
import {postHttp} from "../../api/HttpClient";
import {buildUrlClient} from "../../utils/BuildUrlClient";
import {ForgotRules} from "../../rules/ForgotRules";
export const ForgotPassword = () => {
  const {code} = useParams();
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(false);
  const [data, setData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [messages, setMessages] = useState({
    password: '',
    confirmPassword: '',
  });
  //
  // const openApp = async () => {
  //   try {
  //     const supported = await Linking.canOpenURL(url);
  //     if (supported) {
  //       await Linking.openURL(url);
  //     }
  //   } catch (error) {
  //  console.log(' lista  ', error);
  //   }
  const onChange = (event: any, field: string) => {
    // @ts-ignore
    data[field] = event.target.value;
    setData({...data});
    onValidate(event.target.value, field);
  }
  const onValidate = (value: any, field: string) => {
    const inputRules = getRulesByField(field);
    const resultValidate = validateHelper(field, value, inputRules, data);
    // @ts-ignore
    messages[field] = resultValidate;
    setMessages({...messages});
  };
  const getRulesByField = (field: string) => {
    for (const [key, value] of Object.entries(ForgotRules)) {
      if (key === field) {
        return value;
      }
    }
    return [];
  }
  const checkValidate = () => {
    for (const [key, value] of Object.entries(data)) {
      onValidate(value, key);
    }
  }
  const isValid = () => {
    let count = 0;
    for (let [key, value] of Object.entries(messages)) {
      if (value) {
        count = count + 1;
      }
    }
    return count;
  };
  const onSubmit = async () => {
    checkValidate();
    if (isValid() == 0) {
      const body = {
        password: data.password,
        password_confirmation: data.confirmPassword,
        code: code,
      }
      const {response, success, message} = await postHttp(buildUrlClient('changePassword'), body)
      setMessage(message);
      if (success) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    }
  }
  return <div>
    <section className="p-0 d-flex align-items-center position-relative overflow-hidden">

      <div className="container-fluid">
          <div className="col-12 col-lg-12 d-flex justify-content-center">
            <div className="row " style={{width: '700px'}}>
              <div className="col-sm-10 col-xl-12 m-auto">
                <h1 className="fs-2">Bela2go {status}</h1>
                { message != '' && <div className={"alert " + (status ? 'alert-success' : 'alert-danger')} role="alert">
                  {message}
                </div>}
                {/*<div className="alert alert-danger" role="alert">*/}
                {/*  This is a primary alert—check it out!*/}
                {/*</div>*/}
                <form>
                  <br/>
                  <div className="align-items-center">
                    <div className="d-grid">
                      <div className="col-12">
                        <label className="form-label">Contraseña nueva *</label>
                        <input type="password"
                               className="form-control"
                               value={data.password}
                               onChange={event => onChange(event, 'password')}/>
                        <ShowError message={messages.password} />
                      </div>
                      <div className="col-12">
                        <label className="form-label">Confirmar la contraseña nueva*</label>
                        <input type="password"
                               className="form-control"
                               value={data.confirmPassword}
                               onChange={event => onChange(event, 'confirmPassword')}/>
                        <ShowError message={messages.confirmPassword} />
                      </div>
                      <br/>
                      {/*{ osName === 'Android' &&*/}
                      {/*    <a className={'btn btn-primary mb-0'} href={"bela2goclient://launch?code=" + code}>Iniciar en la  App </a>*/}
                      {/*}*/}
                      {/*{ osName === 'iOS' &&*/}
                      {/*    <a className={'btn btn-primary mb-0'} href={"bela2goclient://launch?code="+ code}>Iniciar en la  App </a>*/}
                      {/*}*/}
                      <div className="col-12 text-center">
                        <button type="button" className="btn btn-success w-50" onClick={() => onSubmit()}>Enviar</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    </section>
  </div>;
}
