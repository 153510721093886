import { createSlice } from '@reduxjs/toolkit'
export interface TypesState {
    types: any[]
}
const initialState: TypesState = {
    types: [],
}
export const typesSlice = createSlice({
    name: 'types',
    initialState,
    reducers: {
        setTypes: (state, action) => {
            state.types = action.payload.types
        },
    },
})
export const { setTypes } = typesSlice.actions
