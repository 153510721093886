import {getHttp} from "../../api/HttpClient";
import {buildUrlClient} from "../../utils/BuildUrlClient";
import {setTypes} from "../slices/typesSlice";

export const getTypes = (page: number = 1 ) => {
    return async (dispatch: any, getState: any) => {
        const  {response, success} =  await getHttp(buildUrlClient('types'))
        if (success) {
            dispatch(setTypes({types: response}))
        }
    }
}
