import React, {useEffect, useState} from "react";
import {validateHelper} from "../helpers/validateHelper";
import {ShowError} from "./showError";
import {RegisterRules} from "../rules/RegisterRules";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {onRegister} from "../store/thunks/authTrunk";
import {RootState} from "../store/store";
import {postHttp} from "../api/HttpClient";
import {buildUrlClient} from "../utils/BuildUrlClient";
import {setShowRegister} from "../store/slices/authSlice";
import {buildUrlBase} from "../utils/BuildUrlBase";
import {RegisterClientRules} from "../rules/RegisterClientRules";

export const RegisterClient = () => {
  const {messages} = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const [data, setData] = useState({
    name: '',
    surnames: '',
    email: '',
    password: '',
    confirmPassword:'',
    mobile:''
  });
  const [errors, setErrors] = useState({
    name: '',
    surnames: '',
    email: '',
    password: '',
    confirmPassword:'',
    mobile:''
  });
  useEffect(() => {
    return () => {
      reloadValidate();
    };
  }, [messages]);

  const reloadValidate = () => {
    if (messages) {
      for (const key in messages) {
        let {field, value} = messages[key]
        // @ts-ignore
        errors[field] = value;
      }
      setErrors({...errors});

    }

  }
  const onChange = (event: any, field: string) => {
    // @ts-ignore
    data[field] = event.target.value;
    setData({...data});
    onValidate(event.target.value, field);
  }
  const onValidate = (value: any, field: string) => {
    const inputRules = getRulesByField(field);
    const resultValidate = validateHelper(field, value, inputRules, data);
    // @ts-ignore
    errors[field] = resultValidate;
    setErrors({...errors});
  };
  const getRulesByField = (field: string) => {
    for (const [key, value] of Object.entries(RegisterClientRules)) {
      if (key === field) {
        return value;
      }
    }
    return [];
  }
  const checkValidate = async () => {
    for (const [key, value] of Object.entries(data)) {
      onValidate(value, key);
    }
  }
  const isValid = () => {
    let count = 0;
    for (let [key, value] of Object.entries(errors)) {
      if (value) {
        count = count + 1;
      }
    }
    return count;
  };
  const onBlurValidateMessage = (key: string, value: any) => {
    // @ts-ignore
    errors[key] = value;
    setErrors({...errors});
  };
  const onSubmit = async () => {
    await checkValidate();
    if (isValid() == 0) {
      const body = {
        name: data.name,
        last_name: data.surnames,
        email: data.email,
        password: data.password,
        mobile: data.mobile,
      };
      const {response, success, errors} = await postHttp(buildUrlBase('registerWebClient'), body);
      if (success) {
        dispatch(setShowRegister({showRegister: false}))
      } else {
        const error = errors.error;
        for (let key in error) {
          onBlurValidateMessage(key, error[key][0]);
        }
      }
    }
  }
  return <div>
    <div className="col-lg-12">
      <div className="card  p-4 p-sm-5 position-relative">
        <form className="row g-3 position-relative">
          <div className="col-md-6 col-lg-12 col-xl-6">
            <label className="form-label">Nombre *</label>
            <input type="text" className="form-control"  onChange={event => onChange(event, 'name')}/>
            <ShowError message={errors.name} />
          </div>
          <div className="col-md-6 col-lg-12 col-xl-6">
            <label className="form-label">Apellidos </label>
            <input type="text" className="form-control" onChange={event => onChange(event, 'surnames')}/>
            <ShowError message={errors.surnames} />
          </div>
          <div className="col-md-12 col-lg-12 col-xl-12">
            <label className="form-label">Email </label>
            <input type="text" className="form-control" onChange={event => onChange(event, 'email')}/>
            <ShowError message={errors.email} />
          </div>
          <div className="col-md-6 col-lg-12 col-xl-6">
            <label className="form-label">Contraseña *</label>
            <input type="email" className="form-control" onChange={event => onChange(event, 'password')}/>
            <ShowError message={errors.password} />
          </div>
          <div className="col-md-6 col-lg-12 col-xl-6">
            <label className="form-label">Confirmar contraseña *</label>
            <input type="email" className="form-control" onChange={event => onChange(event, 'confirmPassword')}/>
            <ShowError message={errors.confirmPassword} />
          </div>
          <div className="col-md-6 col-lg-12 col-xl-6">
            <label className="form-label">Nro. Celular </label>
            <input type="email" className="form-control" onChange={event => onChange(event, 'mobile')}/>
            <ShowError message={errors.mobile} />
          </div>
          <div className="col-12">
            <button type="button" className="btn btn-success mb-0 w-50" onClick={() => onSubmit()}>Enviar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
}
