import {Modal} from "react-bootstrap";
import {RegisterClient} from "./RegisterClient";

export const ModalRegister = (props: any) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <RegisterClient />
            </Modal.Body>
        </Modal>
    );
}
