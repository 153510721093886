import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useGeolocated} from "react-geolocated";
import { GoogleMap, LoadScript, MarkerF, useJsApiLoader} from "@react-google-maps/api";
import {GOOGLE_POSITION, Key} from "../utils/Constants";
import Autocomplete from "react-google-autocomplete";

interface Props {
    visible: boolean,
    onClose: Function,
    onSave: Function,
    map: any
}
export const GoogleModal = ({visible, onClose, onSave, map}: Props) => {
    const [position, setPosition] = useState({
        lat: map.latitude != ''? map.latitude : GOOGLE_POSITION.MAP_LATITUDE,
        lng: map.longitude != '' ?map.longitude :  GOOGLE_POSITION.MAP_LONGITUDE});
    const [coord, setCoord] = useState({lat: map.latitude, lng: map.longitude});
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });
    if (isGeolocationEnabled && isGeolocationAvailable) {
        if (coords) {
            const {latitude, longitude} = coords;
                if (coord.lat == '') {
                    // eslint-disable-next-line no-restricted-globals
                    const result =   confirm('Su posición actual se le asignará a tu dirección? ');
                    if (result) {
                        setCoord({lat: latitude , lng: longitude})
                        setPosition({lat: latitude , lng: longitude})
                    }
                }
        }
    }
    useEffect(() => {
        // (async () => loadLocation())();
    }, []);
const onClickMap = (e: any) => {
if (e?.latLng) {
    setCoord({lat:  e.latLng.lat(), lng: e.latLng.lng()});
    setPosition({lat:  e.latLng.lat(), lng: e.latLng.lng()});
}
}
const onPlace = (place: any) => {
    if (place) {
        setCoord({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
        setPosition({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
    }
}
    return <Modal show={visible} onHide={() =>onClose()}  size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Agregar dirección</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <div className={'row'}>
                <div className={'p-3'}>
                    <Autocomplete
                        className={'form-control'}
                        apiKey={Key.google}
                        onPlaceSelected={(place:any) => onPlace(place)}
                    />
                </div>
                <div style={{ height: '500px', width: '100%' }}>
                    {<GoogleMap
                        onClick={e => onClickMap(e)}
                        mapContainerStyle={{  width: '100%',
                            height: '100%'}}
                        center={{ lat: position.lat, lng: position.lng }}
                        zoom={13}
                    >
                        {coord.lat != '' && <MarkerF label={''}
                                                     position={{ lat: parseFloat(coord.lat),
                                                         lng: parseFloat(coord.lng) }} />}
                    </GoogleMap> }
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => onClose()}>
                Cerrar
            </Button>
            <Button variant="primary" onClick={() => onSave(coord)}>
                Confirmar
            </Button>
        </Modal.Footer>
    </Modal>
};
