import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {getHttp} from "../../api/HttpClient";
import {buildUrlClient} from "../../utils/BuildUrlClient";

interface Props {
  visible: boolean,
  item: any,
  onClose: Function,
}
export const ScheduleDetail = ({visible, onClose, item}: Props) => {
  const [payment, setPayment] = useState({} as any);

  useEffect(() => {
    (async () => await loadData())();
  }, []);

  const loadData = async () => {
    const params = {
      id: item?.id,
    };
    const {response, success} = await getHttp(buildUrlClient('paymentByAppointment'), params);
    if (success) {
      setPayment(response);
    }
  }
  const showProfessional = () => {
    return <>
      {item && <div className="d-md-flex" >
        <div className="avatar avatar-md me-4 flex-shrink-0">
          {item?.appointment_service &&
          <img className="avatar-img rounded-circle" src={item?.appointment_service[0]?.perfilLink} alt="avatar" /> }
        </div>
        <div>
          <div className="d-sm-flex mt-1 mt-md-0 align-items-center">
            {item?.appointment_service &&
            <h5 className="me-3 mb-0">Profesional: {item?.appointment_service[0]?.professional_name}</h5>
            }
          </div>
        </div>
      </div>
      }
    </>

  }
  const showResult = () => {
    const {result} = payment;
 return   <ul className="list-group list-group-borderless mb-2">
      <li className="list-group-item px-0 d-flex justify-content-between">
        <span className="h6 fw-light mb-0">Subtotal</span>
        <span className="h6 fw-light mb-0 fw-bold">${result?.total_cost}</span>
      </li>
      <li className="list-group-item px-0 d-flex justify-content-between">
        <span className="h6 fw-light mb-0">Transporte</span>
        <span className="h6 fw-light mb-0 fw-bold">${result?.transport_cost}</span>
      </li>

      <li className="list-group-item px-0 d-flex justify-content-between">
        <span className="h6 fw-light mb-0">Descuento</span>
        <span className="text-danger">-${result?.discount}</span>
      </li>
      <li className="list-group-item px-0 d-flex justify-content-between">
        <span className="h5 mb-0">Total</span>
        <span className="h5 mb-0">${result?.total}</span>
      </li>
    </ul>
  }
  return <Modal show={visible} onHide={() =>onClose()}  size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Detalle de la {item?.Cita}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {showProfessional()}
      {item?.appointment_service?.map((ele: any, index: number) => (
          <div className="d-md-flex" key={index}>
            <div className="avatar avatar-md me-4 flex-shrink-0">
            </div>
            <div>
              <h6 className="mb-0">Fecha de la Cita: {ele?.date_scheduled}</h6>
              <div className="d-sm-flex mt-1 mt-md-0 align-items-center">
                <h6 className="mb-0">Servicio: {ele?.service_name}</h6>
              </div>
              <p className="small mb-2">Horas: {ele?.time_ini} - {ele?.time_end}</p>
            </div>
          </div>
      ))}
      {payment && <div>
        {payment?.generateqr && <div className={'text-center'}>
          <img src={payment.generateqr.qrImage} />
        </div>}
      </div>}
      {showResult()}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => onClose()}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
}
