import React, {useEffect, useState} from "react";
import {Services} from "./Services";
import {CalendarView} from "./CalendarView";
import {Payment} from "./Payment";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {STEPPERS} from "../utils/Constants";
import {setReloadAddress, setShowModalAddrress, setStepper} from "../store/slices/appointmentSlice";
import {onCalendar, onConfirm} from "../store/thunks/appointmentThunk";
import {checkServicesAssigned} from "../core/calendar/checkServicesAssigned";
import {getCheckStepperCondition} from "../core/calendar/getCheckStepperCondition";
import {getNextStepper} from "../core/calendar/getNextStepper";
import {AddressModal} from "./AddressModal";
import {getHttp} from "../api/HttpClient";
import {buildUrlClient} from "../utils/BuildUrlClient";
import {getCurrentTime} from "../core/calendar/getCurrentTime";
import {checkAddress} from "../store/thunks/AddressThunk";

export const Appointment = () => {
    const dispatch = useAppDispatch()
    const {stepper, payment, showModalAddrress, counterAddrress } = useAppSelector((state: RootState) => state.appointment) as any;
    const {confirmSchedule, qr} = useAppSelector((state: RootState) => state.confirm)
    const onStepper = async (param: string) => {
        if (counterAddrress > 0) {
            const stepperCondition = await getCheckStepperCondition(stepper, payment);
            if (stepperCondition) {
                const next = await getNextStepper(stepper);
                switch (stepper) {
                    case STEPPERS.CALENDAR:
                        dispatch(onCalendar(payment, next));
                        return;
                    case STEPPERS.PAYMENT:
                        dispatch(onConfirm(payment, next));
                        return;
                }
            } else {
                alert('Asigne las horas a los servicios');
            }
        } else {
            dispatch(setShowModalAddrress({showModalAddrress: true}))
            alert('Registre su direccion')
        }
    }
    const onCloseModal = () => {

        dispatch(checkAddress());
        dispatch(setShowModalAddrress({showModalAddrress: false}))
    }

  return <>
      {confirmSchedule ? <>
              <div className="row">
                  <div className="col-md-8 text-center mx-auto">
                      <figure className="ms-n4">
                          <svg width="334.7px" height="224px">
                              <circle className="fill-success" cx="175.3" cy="116.6" r="69.5"/>
                              <path className="fill-white"
                                    d="M145.1,107.5c0.4,0.3,0.7,0.6,1,0.9c5.4,5.3,10.7,10.6,16,15.9c0.9,0.9,1.3,0.9,2.2,0 c13.3-13.4,26.7-26.7,40-40.1c1-1,1.5-0.9,2.4,0c3.4,3.5,6.9,6.9,10.4,10.2c0.7,0.7,0.7,1.1,0,1.8c-17.7,17.6-35.3,35.3-53,53 c-0.7,0.7-1,0.7-1.8,0c-9.6-9.6-19.2-19.1-28.8-28.6c-0.7-0.7-0.9-1.1-0.1-1.9c3.6-3.5,7.2-7,10.8-10.5 C144.5,108,144.8,107.8,145.1,107.5z"/>
                          </svg>
                      </figure>
                      <h3>Su solicitud de servicio ha sido enviado exitosamente.</h3>
                      {qr != null && <div>

                          <div className="card" style={{width: '18rem'}}>
                              <img src={qr?.qrImage} className="card-img-top"/>
                              <div className="card-body">
                                  <h5 className="card-title">QR</h5>
                                  <p className="card-text">Some quick </p>
                                  <a href="#" className="btn btn-primary">Descargar</a>
                              </div>
                          </div>
                      </div>}
                      <a href="/" className="btn btn-success w-50 font-16" >Ir a Inicio</a>
                  </div>
              </div>
          </> :
          <div className="page-content-wrapper">
          <div id="stepper" className="bs-stepper stepper-outline">
              <div className="card-header bg-light-app border-bottom px-lg-5">
                  <div className="bs-stepper-header" role="tablist">
                      <div className={`step  ${stepper === STEPPERS.CALENDAR ? "active" : "dstepper-none"}`} data-target="#step-2">
                          <div className="d-grid text-center align-items-center">
                              <button type="button" className="btn btn-link step-trigger mb-0"
                                      role="tab"
                                      onClick={() => onStepper(STEPPERS.CALENDAR)}
                                      id="steppertrigger2"
                                      aria-controls="step-2">
                                  <span className="bs-stepper-circle">1</span>
                              </button>
                              <h6 className="bs-stepper-label d-none d-md-block">Hora de agenda</h6>
                          </div>
                      </div>
                      <div className="line"></div>
                      <div className={`step  ${stepper === STEPPERS.PAYMENT ? "active" : "dstepper-none"}`} data-target="#step-3">
                          <div className="d-grid text-center align-items-center">
                              <button type="button" className="btn btn-link step-trigger mb-0"
                                      role="tab"
                                      onClick={() => onStepper(STEPPERS.PAYMENT)}
                                      id="steppertrigger3"
                                      aria-controls="step-3">
                                  <span className="bs-stepper-circle">2</span>
                              </button>
                              <h6 className="bs-stepper-label d-none d-md-block">Confirmar</h6>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="container">
                  <div className="bs-stepper-content">
                      <div id="step-2" role="tabpanel" className={`content fade dstepper-block ${stepper == STEPPERS.CALENDAR ? "active" : "dstepper-none"}`} aria-labelledby="steppertrigger2">
                          <h4>Seleccione el servicio, asigne la fecha y las horas. {showModalAddrress}</h4>
                          <hr/>
                          {stepper == STEPPERS.CALENDAR && <CalendarView /> }
                      </div>
                      <div id="step-3" role="tabpanel" className={`content fade dstepper-block ${stepper == STEPPERS.PAYMENT ? "active" : "dstepper-none"}`} aria-labelledby="steppertrigger3">
                          <h4></h4>
                          <hr/>
                          {stepper == STEPPERS.PAYMENT && <Payment /> }
                      </div>
                  </div>
              </div>
          </div>
      </div>}

      {showModalAddrress && <AddressModal
          fullscreen={true}
          visible={showModalAddrress}
          onClose={() => onCloseModal()}/>}
  </>;
}
