import React, {useState} from "react";
import {getHttp} from "../api/HttpClient";
import {buildUrlClient} from "../utils/BuildUrlClient";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
interface Props {
    visible: boolean,
    value: string,
    placeholder: string,
    onClose: Function,
    onSave: Function
}

export const SearchAutocomplete = ({visible, value,placeholder, onClose, onSave}: Props) => {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const updateValue = (event: any) => {

        setInputValue(event.target.value);
        // requests(newValue);
    };
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };
    const handleOnSearch = async (string: any, results: any) => {
        const {response, success} = await getHttp(buildUrlClient('selectClient'), {input:string});

        if (success) {
            setSuggestions(response);
        }
    }

    const handleOnHover = (result: any) => {
        // the item hovered

    }

    const handleOnSelect = (item: any) => {
        // eslint-disable-next-line no-restricted-globals
        const result = confirm('¿Está seguro de cambiar de usuario? '+ item.name +' '+ item.last_name);
        if ( result) {
            onSave(item);
        } else {

        }
    }

    const handleOnFocus = () => {

    }
    const handleOnClear = () => {
      // alert("alert")
    }
    const formatResult = (item: any) => {
        return (
            <div className="d-sm-flex">
                <div className="ms-0 ms-sm-2 mt-2 mt-sm-0">
                    <h6 className="mb-0">{item.name} {' '} {item.last_name}</h6>
                    <p className="mb-0">{item.email}</p>
                </div>
            </div>
        )
    }
    return (
            <div style={{
                width: 400, position: 'relative',
                zIndex: 99
            }}>
                <ReactSearchAutocomplete
                    items={suggestions}
                    onClear={() => handleOnClear()}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={(value) =>handleOnSelect(value)}
                    onFocus={handleOnFocus}
                    // autoFocus
                    formatResult={formatResult}
                />
            </div>
    );
}
