import {HeaderAppointment} from "../layout/headerAppointment";
import {UseListHock} from "../hooks/useListHock";
import {useEffect} from "react";
import ReactPaginate from "react-paginate";
import {buildUrlClient} from "../utils/BuildUrlClient";
import moment from "moment";
import {Welcome} from "../compoments/Welcome";
moment.locale('es');
export const NotificationPage = () => {
  const {list, removeItem, getDataList, addParams, total, page} = UseListHock(buildUrlClient('notification'));

  useEffect(() => {
    (async () => await getDataList(1))();
  }, []);
  const onPageChange = async (page: any) => {
    await getDataList(page.selected + 1)
  }
  return <>
    <HeaderAppointment />
    <div className={'container'}>
      <Welcome />
    </div>
    <div className={'container'}>
      <nav  aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href={'/'}>Inicio</a></li>
          <li className="breadcrumb-item active" aria-current="page">Noticicaciones</li>
        </ol>
      </nav>
      {list.map((item: any, index: number) => (
          <div className="d-sm-flex" key={index}>
            {/*<img className="avatar avatar-lg rounded-circle float-start me-3" src="assets/images/avatar/01.jpg"*/}
            {/*     alt="avatar" />*/}
              <div>
                <div className="mb-3 d-sm-flex justify-content-sm-between align-items-center">
                  <div>
                    <h5 className="m-0">{item.title}</h5>
                    <span className="me-3 small"> {moment.utc(item.created_at).local().format('LLLL')}</span>
                  </div>
                </div>
                <p>{item.body} </p>
              </div>
          </div>
      ))}

      <div className="card-footer bg-transparent pt-0">
        <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
          <p className="mb-0 text-center text-sm-start">Total páginas {total}</p>
          <ReactPaginate
              breakLabel="..."
              nextLabel="Sigiente >"
              onPageChange={(page) => onPageChange(page) }
              pageRangeDisplayed={5}
              pageCount={total}
              previousLabel="< Anterior"
              renderOnZeroPageCount={null}
              containerClassName={"pagination pagination-sm pagination-primary-soft d-inline-block d-md-flex rounded mb-0"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  </>;
}
